import React from "react";
import { SpinnerCompContainer, SpinnerCompContent } from "./StyledSpinnerComp";

const SpinnerComp = () => {
  return (
    <SpinnerCompContainer>
      <SpinnerCompContent />
    </SpinnerCompContainer>
  );
};

export default SpinnerComp;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import DataService from "../components/services/dataService";

const dataService = new DataService();

const loadEvents = createAsyncThunk("overview/loadEvents", async () => {
  const response = await dataService.getData("/api/events/upcoming");
  return response.data;
});

export const overviewSlice = createSlice({
  name: "overview",
  initialState: { events: [], loading: false, error: "" },
  reducers: {},
  extraReducers: {
    [loadEvents.pending]: (state) => {
      if (!state.loading && !state.events.length) {
        state.loading = true;
      }
    },
    [loadEvents.fulfilled]: (state, action) => {
      if (state.loading) {
        state.loading = false;
        state.events = action.payload;
      }
    },
    [loadEvents.rejected]: (state, action) => {
      if (state.loading) {
        state.loading = false;
        state.events = [];
        state.error = action.error;
      }
    },
  },
});

export const { init } = overviewSlice.actions;

export const selectEvents = (state) => {
  return state.overview.events;
};

export const selectLoading = (state) => {
  return state.overview.loading;
};

export { loadEvents };
export default overviewSlice.reducer;

import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  totalAnswers: {
    fontWeight: "900",
    color: "black",
    padding: "10px 0px",
  },
  totalAnswersSpan: {
    fontWeight: "500",
  },
});

const SurveyCardFooter = ({ total }) => {
  const classes = useStyles();
  const totalAnswersTitle = I18n.t("surveys.answers.index");

  return (
    <h6 className={classes.totalAnswers}>
      {totalAnswersTitle + ": "}
      <span className={classes.totalAnswersSpan}>{total}</span>
    </h6>
  );
};

SurveyCardFooter.propTypes = {
  total: PropTypes.number,
};

export default SurveyCardFooter;

import { createSelectorCreator, defaultMemoize } from "reselect";
import isEqual from "lodash.isequal";

// Use custom comparer to deep compare objects and arrays with isEqual
const customDeepCompare = (prev, next) => isEqual(prev, next);

export const customSelectorCreatorDeepCompare = createSelectorCreator(
  defaultMemoize,
  {
    equalityCheck: customDeepCompare,
    resultEqualityCheck: customDeepCompare,
    maxSize: 10,
  },
);

import React from "react";
import PropTypes from "prop-types";

const ThankYouMessage = ({ withDottedLine }) => {
  return (
    <>
      {withDottedLine && (
        <div className={"line line-lg line-dashed pull-in"}></div>
      )}
      <p> {I18n.t("surveys.new_attempt.success")} </p>
      {withDottedLine && (
        <div className={"line line-lg line-dashed pull-in"}></div>
      )}
    </>
  );
};

ThankYouMessage.propTypes = {
  withDottedLine: PropTypes.bool,
};

export default ThankYouMessage;

import React from "react";
import PropTypes from "prop-types";

import FormQuestionVertical from "./formQuestionVertical";
import FormQuestionHorizontal from "./formQuestionHorizontal";

const FormQuestion = ({ horizontal = false }) => {
  return horizontal ? <FormQuestionHorizontal /> : <FormQuestionVertical />;
};

FormQuestion.propTypes = {
  horizontal: PropTypes.bool,
};

export default FormQuestion;

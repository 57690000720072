/* Global I18n */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedRoom,
  setSelectedRoom,
  selectActiveQuestionRooms,
} from "@store/slices/questionRoomsSlice";

import { Button, Span } from "./StyledEventQuestionRoomList";

const EventQuestionRoomsList = () => {
  const dispatch = useDispatch();
  const questionRooms = useSelector(selectActiveQuestionRooms);
  const selectedRoom = useSelector(selectSelectedRoom);

  const isRoomSelected = (questionRoom) => {
    if (!selectedRoom) return false;
    return selectedRoom.id === questionRoom.id;
  };

  const changeSelectedRoom = (questionRoom) => {
    dispatch(setSelectedRoom(questionRoom.id));
  };

  return (
    <div className="question-room-list">
      {questionRooms.map((questionRoom, index) => {
        return (
          <Button
            onClick={() => changeSelectedRoom(questionRoom)}
            key={questionRoom.id}
            className={
              isRoomSelected(questionRoom)
                ? "active selected question-room-button"
                : "question-room-button"
            }
          >
            <Span className={isRoomSelected(questionRoom) ? "active" : ""}>
              {index + 1}
            </Span>
            {questionRoom.title || I18n.t("questions.title")}
          </Button>
        );
      })}
    </div>
  );
};

export default EventQuestionRoomsList;

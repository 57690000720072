import { customSelectorCreatorDeepCompare } from "@store/selectors/customSelectorCreator";

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import DataService from "@services/dataService";

const dataService = new DataService();

export const getQuestionRooms = createAsyncThunk(
  "questionRooms/getQuestionRooms",
  async ({ eventId }, thunkAPI) => {
    let url = `/api/events/${eventId}/questions_rooms`;
    const jwt = thunkAPI.getState().jwt.token;

    return dataService.getData(url, jwt).then((res) => res.data);
  },
);

export const updateQuestionRoomOnServer = createAsyncThunk(
  "questionRooms/updateQuestionRoomOnServer",
  async ({ id, changes }) => {
    let url = `/admin/chat_rooms/${id}.json`;

    return dataService
      .putData(url, { chat_room: changes })
      .then((res) => res.data);
  },
);

export const moveQuestionOnServer = createAsyncThunk(
  "questionRooms/moveQuestionOnServer",
  async ({ chatRoomId, ...options }) => {
    let url = `/admin/chat_rooms/${chatRoomId}/move_question`;

    return dataService
      .putData(url, { chat_room: options })
      .then((res) => res.data);
  },
);

const questionRoomsAdapter = createEntityAdapter();

const questionRoomsSelectors = questionRoomsAdapter.getSelectors(
  (state) => state.questionRooms,
);

export const questionRoomsSlice = createSlice({
  name: "questionRooms",
  initialState: questionRoomsAdapter.getInitialState({
    shown: false,
    selectedRoom: -1,
  }),
  reducers: {
    setQuestionRoomsShown(state, action) {
      state.shown = action.payload;
    },
    setSelectedRoom(state, action) {
      state.selectedRoom = action.payload;
    },
    updateQuestionRoom(state, action) {
      questionRoomsAdapter.updateOne(state, action.payload);
    },
    addQuestionRoom: questionRoomsAdapter.upsertOne,
    removeQuestionRoom(state, action) {
      questionRoomsAdapter.removeOne(state, action.payload);
    },
    updateQuestionRoomOrderPartially(state, action) {
      // state order can also update patially, so we need to make a deep merge
      // of the state order map
      const room = questionRoomsAdapter
        .getSelectors()
        .selectById(state, action.payload.id);

      const newOrder = {
        ...room.order_state_map,
        ...action.payload.chat_room.order_state_map,
      };

      questionRoomsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: {
          order_state_map: newOrder,
        },
      });
    },
    updateQuestionRoomOrderLocal(state, action) {
      const room = state.entities[action.payload.id];
      action.payload.newStateOrderMaps.forEach((item) => {
        room.order_state_map[item.state] = item.newMap;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getQuestionRooms.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getQuestionRooms.fulfilled, (state, action) => {
      const data = action.payload.questions_rooms;
      questionRoomsAdapter.setAll(state, data);
      state.loading = false;
    });
    builder.addCase(getQuestionRooms.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(updateQuestionRoomOnServer.pending, (state, action) => {
      questionRoomsAdapter.updateOne(state, {
        id: action.meta.arg.id,
        changes: action.meta.arg.changes,
      });
      state.loading = true;
    });
    builder.addCase(updateQuestionRoomOnServer.fulfilled, (state, action) => {
      questionRoomsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      });
      state.loading = false;
    });
    builder.addCase(updateQuestionRoomOnServer.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

// actions
export const {
  setQuestionRoomsShown,
  setSelectedRoom,
  updateQuestionRoom,
  removeQuestionRoom,
  addQuestionRoom,
  updateQuestionRoomOrderLocal,
  updateQuestionRoomOrderPartially,
} = questionRoomsSlice.actions;

const filterActive = (rooms) =>
  rooms.filter((questionRoom) => questionRoom.active);

export const selectFirstActiveChatRoom = (state) =>
  filterActive(selectQuestionRooms(state))[0];

export const selectQuestionRoomsShown = (state) => {
  return state.questionRooms.shown;
};
export const selectQuestionRooms = (state) => {
  return questionRoomsSelectors.selectAll(state);
};

export const selectSelectedRoom = (state) =>
  questionRoomsSelectors.selectById(state, state.questionRooms.selectedRoom);

export const selectSelectedRoomId = (state) => state.questionRooms.selectedRoom;

export const selectActiveQuestionRooms = (state) => {
  return filterActive(selectQuestionRooms(state));
};

export const selectOrderStateMap = customSelectorCreatorDeepCompare(
  selectSelectedRoom,
  (room) => room?.order_state_map,
);

export const {
  selectAll: selectAllQuestionRooms,
  selectById: selectQuestionRoomById,
} = questionRoomsSelectors;

export default questionRoomsSlice.reducer;

/* global Live */

export default class PingService {
  speakerPingTimeout = undefined;
  speakerPingDelay = 10000;

  startSpeakerPing = (eventId) => {
    this.stopSpeakerPing();
    this.pingSpeaker(eventId);
    this.speakerPingTimeout = setTimeout(() => {
      this.startSpeakerPing(eventId);
    }, this.speakerPingDelay);
  };

  stopSpeakerPing = () => {
    clearTimeout(this.speakerPingTimeout);
  };

  pingSpeaker = (eventId) => {
    return this.client().publish(this.eventChannel(eventId), this.dataObj());
  };

  pingStream = (streamId, dataObj) => {
    return this.client().publish(this.streamChannel(streamId), dataObj);
  };

  streamChannel = (streamId) => {
    return Live.getEntityChannel("stream", streamId, "ping");
  };

  eventChannel = (eventId) => {
    return Live.getEntityChannel("event", eventId, "ping");
  };

  dataObj = () => {
    return {
      type: "speaker",
    };
  };

  client = () => {
    return Live.getClient();
  };
}

/* global Live */

import EventService from "./eventService";

export default class WebSocketService {
  constructor() {
    const eventService = new EventService();
    this.eventId = eventService.getEventIdFromAppConfig();
    this.fayeService = window.Live || new FayeService();
  }

  // State here means, all parameter of the event.
  // Including settings event state(live etc)

  subscribeToEventStateChange(id, callback) {
    // returns the subscription object from faye to the dispatch call (as a promise)
    return this.fayeService.subscribeToEvent(id, "state", callback);
  }

  unsubscribeFromEventStateChange(id) {
    this.fayeService.unsubscribeFromEvent(id, "state");
  }

  subscribeToQuestionsRoomOrder(roomId, callback) {
    this.fayeService.subscribeTo(`/questions_room/${roomId}/order`, callback);
  }

  unsubscribeFromQuestionsRoomOrder(roomId) {
    this.fayeService.unsubscribeFrom(`/questions_room/${roomId}/order`);
  }

  subscribeToQuestionRoomChat(roomId, eventId, callback) {
    this.fayeService.subscribeToEvent(eventId, `chat_room/${roomId}`, callback);
  }

  unsubscribeFromQuestionRoomChat(roomId, eventId, callback) {
    this.fayeService.unsubscribeFromEvent(
      eventId,
      `chat_room/${roomId}`,
      callback,
    );
  }

  subscribeToQuestionRooms(id, callback) {
    this.fayeService.subscribeToEvent(id, "chat_rooms", callback);
  }

  unsubscribeFromQuestionRooms(id, callback) {
    this.fayeService.unsubscribeFromEvent(id, "chat_rooms", callback);
  }

  subscribeToStreams(id, callback) {
    this.fayeService.subscribeToEvent(id, "streams", callback);
  }

  unsubscribeFromStreams(id, callback) {
    this.fayeService.unsubscribeFromEvent(id, "streams", callback);
  }

  subscribeToCountdown(id, callback) {
    this.fayeService.subscribeToEvent(id, "countdown", callback);
  }

  unsubscribeFromCountdown(id, callback) {
    this.fayeService.unsubscribeFromEvent(id, "countdown", callback);
  }

  subscribeToIframes(id, callback) {
    this.fayeService.subscribeToEvent(id, "iframes", callback);
  }

  unsubscribeFromIframes(id, callback) {
    this.fayeService.unsubscribeFromEvent(id, "iframes", callback);
  }

  subscribeSocketsConference(id, callback) {
    this.fayeService.subscribeToEvent(id, "conference", callback);
  }

  unsubscribeSocketsConference(id) {
    this.fayeService.unsubscribeFromEvent(id, "conference");
  }

  sendModerationMessage(message) {
    this.fayeService.publishToEvent(this.eventId, "conference", message);
  }

  sendQuestionPushChange(question, eventId) {
    this.fayeService.publishToEvent(eventId, "pushedQuestion", {
      question_id: question.id,
      pushed: question.pushed,
    });
  }
}

import React from "react";
import PropTypes from "prop-types";

import {
  CountdownMessageWrapper,
  ProgressBarContainer,
  CountdownMessageText,
  CountdownData,
  InProgress,
} from "./StyledCountdownProgressBar";

const CountdownProgressBar = ({ color, width, duration, message }) => {
  return (
    <ProgressBarContainer>
      <CountdownMessageWrapper>
        <CountdownMessageText>
          <CountdownData>{message}</CountdownData>
        </CountdownMessageText>
      </CountdownMessageWrapper>
      <InProgress color={color} width={width}>
        <CountdownData>{duration}</CountdownData>
      </InProgress>
    </ProgressBarContainer>
  );
};

CountdownProgressBar.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  duration: PropTypes.string,
  message: PropTypes.string,
};

export default CountdownProgressBar;

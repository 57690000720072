import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import DataService from "@services/dataService";

const dataService = new DataService();

const loadQuestions = createAsyncThunk(
  "questions/loadQuestions",
  async (params) => {
    const { url, authorId, questionVoting } = params;
    const response = await dataService.getData(url).then((result) => {
      const data = result.data;
      const ownVotes = data.voted_on;
      const listQuestions = listFilter(
        data.questions,
        authorId,
        questionVoting,
        ownVotes,
      );
      return {
        list: listQuestions,
        currentOffset: data.current_offset,
        totalQuestions: data.total_questions_count,
      };
    });
    return response;
  },
);

const listFilter = (data, author, questionVoting, ownVotes) => {
  return data.map((item) => {
    if (questionVoting) {
      item.isAnswered = item.state === 300;
      item.authorOwner = item.attendee_id === author;
      item.voted = ownVotes.includes(item.encoded_id);
    }
    item.id = item.encoded_id;
    return item;
  });
};
export const questionsListSlice = createSlice({
  name: "questionsList",
  initialState: {
    questions: [],
    offset: 0,
    totalQuestions: 0,
    filterQuestions: [],
    sortQuestions: [],
    loading: false,
    error: "",
    failedRequests: 0,
  },
  reducers: {},
  extraReducers: {
    [loadQuestions.pending]: (state) => {
      if (!state.loading && !state.questions.length) {
        state.loading = true;
      }
    },
    [loadQuestions.fulfilled]: (state, action) => {
      const data = action.payload;
      state.loading = false;
      state.questions = data.list;
      state.offset = data.currentOffset;
      state.totalQuestions = data.totalQuestions;
      state.error = "";
      state.failedRequests = 0;
    },
    [loadQuestions.rejected]: (state, action) => {
      state.loading = false;
      state.questions = [];
      state.error = action.error;
      state.failedRequests = state.failedRequests + 1;
    },
  },
});

export const selectFailedRequests = (state) => {
  return state.questionsList.failedRequests;
};

export const selectQuestions = (state) => {
  return state.questionsList;
};

export const selectIncomingQuestions = (state) => {
  return (
    selectQuestions(state).questions.filter(
      (question) => question.state === 0,
    ) || []
  );
};

export const selectLiveQuestions = (state) => {
  return (
    selectQuestions(state).questions.filter(
      (question) => question.state === 200,
    ) || []
  );
};

export const selectAnsweredQuestions = (state) => {
  return (
    selectQuestions(state).questions.filter(
      (question) => question.state === 300,
    ) || []
  );
};
export { loadQuestions };

export default questionsListSlice.reducer;

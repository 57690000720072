import { styled } from "@mui/system";

const FloatingTagHolder = styled("div")({
  position: "absolute",
  top: "0",
  right: "0",
  transform: "translateX(100%)",
  zIndex: "100",
});

export default FloatingTagHolder;

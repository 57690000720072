import React from "react";
import PropTypes from "prop-types";

const SurveyAnonymouseNote = ({ note }) => {
  return (
    <p id="anonymous_warning">
      <em>{note}</em>
    </p>
  );
};

SurveyAnonymouseNote.propTypes = {
  note: PropTypes.string,
};

export default SurveyAnonymouseNote;

import { styled } from "@mui/system";
import Spinner from "@components/Spinner/Spinner";

export const SpinnerCompContainer = styled("div")({
  minHeight: "200px",
  position: "relative",
});

export const SpinnerCompContent = styled(Spinner)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
});

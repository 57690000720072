import { selectActiveEvent } from "@store/slices/eventsSlice";
import { createSelector } from "@reduxjs/toolkit";

// memoization helper to reduce rerendering
export const selectActiveEventState = createSelector(
  selectActiveEvent,
  (event) => event && (event.overwrittenState || event.state),
);

export const selectActiveEventQAEnabled = createSelector(
  selectActiveEvent,
  (event) => event?.questions_enabled,
);

const selectActiveEventQAEnabledPre = createSelector(
  selectActiveEvent,
  (event) => event?.questions_enabled_pre,
);

const selectActiveEventPubQAEnabled = createSelector(
  selectActiveEvent,
  (event) => event?.public_questions,
);

const selectActiveEventProjectorView = createSelector(
  selectActiveEvent,
  (event) => event?.projector_view,
);

// exports
export const selectActiveEventPolicy = createSelector(
  selectActiveEvent,
  (event) => event?.policy,
);

export const selectActiveDistributionType = createSelector(
  selectActiveEvent,
  (event) => event?.distribution_type,
);

export const selectMandatoryName = createSelector(
  selectActiveEvent,
  (event) => event?.mandatory_name,
);

export const selectAnonymousQuestions = createSelector(
  selectActiveEvent,
  (event) => event?.anonymous_questions,
);

export const selectQuestionVoting = createSelector(
  selectActiveEvent,
  (event) => event?.question_voting,
);

export const selectLanguages = createSelector(
  selectActiveEvent,
  (event) => event?.languages,
);

export const selectPublicQuestionsLiveOnly = createSelector(
  selectActiveEvent,
  (event) => event?.public_questions_live_only,
);

export const selectAttendeesPhoneDialConferenceId = createSelector(
  selectActiveEvent,
  (event) => event?.attendeesPhoneDialConferenceId,
);

export const selectShowQA = createSelector(
  [
    selectActiveEventState,
    selectActiveEventQAEnabled,
    selectActiveEventQAEnabledPre,
    selectActiveEventProjectorView,
  ],
  (state, qa_during_live, qa_pre_event, projector) => {
    return (
      !projector &&
      !!(
        (state === "pre" && qa_pre_event) ||
        (state === "live" && qa_during_live)
      )
    );
  },
);

export const selectShowPubQA = createSelector(
  [selectShowQA, selectActiveEventPubQAEnabled],
  (qa_visible, pub_qa_enabled) => {
    return qa_visible && pub_qa_enabled;
  },
);

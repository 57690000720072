import { styled } from "@mui/system";

export const Button = styled("div")({
  minHeight: "50px",
  margin: "15px",
  padding: "11px",
  borderRadius: "6px",
  background: "#C6C6C6",
  fontSize: "17px",
  color: "white",
  cursor: "pointer",
  "&.active": {
    background: "#594f8d",
  },
});
export const Span = styled("span")({
  background: "#FFFFFF",
  boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.25)",
  color: "#C6C6C6",
  borderRadius: "100%",
  width: "26px",
  height: "26px",
  display: "inline-block",
  textAlign: "center",
  marginRight: "10px",
  fontWeight: "bold",
  "&.active": {
    color: "#594f8d",
  },
});

import {
  createEntityAdapter,
  createAsyncThunk,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";
import DataService from "@services/dataService";

const dataService = new DataService();

export const getSurveyOptionAnswers = createAsyncThunk(
  "surveys/getSurveyOptionAnswers",
  async ({ optionId, page }, thunkAPI) => {
    const currentPage =
      page || thunkAPI.getState().surveyAnswers.pageMap[optionId] || 1;
    const url = `/api/survey_options/${optionId}/answers?page=${currentPage}`;
    const jwt = thunkAPI.getState().jwt.token;

    const data = await dataService.getData(url, jwt).then((res) => res.data);

    const { page_size, current_page, answers } = data;

    if (answers.length == page_size) {
      thunkAPI.dispatch(
        getSurveyOptionAnswers({ optionId, page: current_page + 1 }),
      );
    }

    return data;
  },
);

const surveyAnswersAdapter = createEntityAdapter();

const surveyAnswersSelectors = surveyAnswersAdapter.getSelectors(
  (state) => state.surveyAnswers,
);

const surveysAnswersSlice = createSlice({
  name: "surveyAnswers",
  initialState: surveyAnswersAdapter.getInitialState({
    error: "",
    pageMap: {},
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSurveyOptionAnswers.fulfilled, (state, action) => {
      surveyAnswersAdapter.upsertMany(state, action.payload.answers);
      state.pageMap[action.meta.arg.optionId] = action.payload.current_page;
    });
    builder.addCase(getSurveyOptionAnswers.rejected, (state, action) => {
      state.error = action.error.message;
    });
  },
});

export const selectAnswersForOption = createSelector(
  (state) => state,
  (_, optionId) => optionId,
  (state, optionId) => {
    return surveyAnswersSelectors.selectAll(state).filter((answer) => {
      return answer.option_id === optionId;
    });
  },
);

export const selectAllAnswersFromOptions = createSelector(
  (state) => state,
  (_, options) => options,
  (state, options) => {
    return options.map((option) => {
      return surveyAnswersSelectors.selectAll(state).filter((answer) => {
        return answer.option_id === option.id;
      });
    });
  },
);

export default surveysAnswersSlice.reducer;

import React from "react";

import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { selectActiveEvent } from "@store/slices/eventsSlice";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  logo: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "120px",
  },
});

const EventLogo = () => {
  const { logo } = useSelector(selectActiveEvent);
  if (!(logo && logo.url.header && logo.url.header2x)) {
    return <></>;
  }
  const classes = useStyles();
  const srcSet = `${logo.url.header} 100w, ` + `${logo.url.header2x} 200w`;

  return (
    <Box
      className={classes.logo}
      component="img"
      alt="Event Logo"
      src={logo.url.header}
      srcSet={srcSet}
    />
  );
};

export default EventLogo;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  boxText: {
    fontSize: "12px",
    padding: "5px 10px",
    resize: "vertical",
    maxHeight: "344px",
  },
}));

const AuthorField = ({
  authorLabel,
  authorName,
  styles,
  disable,
  callKeyDown,
  callback,
}) => {
  const classes = useStyles();
  const [author, setAuthor] = useState(authorName);

  useEffect(() => {
    setAuthor(authorName);
  }, [authorName]);

  const handleBlur = () => {
    if (author === "" && authorName === "Anonymous") {
      setAuthor(() => authorName);
    }
    callback(author);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      callKeyDown(author);
    }
  };

  const handleChange = (e) => {
    setAuthor(e.target.value);
  };

  return (
    <div className="form-group">
      <label htmlFor="questionAuthor">{authorLabel}</label>
      <input
        type="text"
        className={`form-control ${classes.boxText} ${styles}`}
        id="questionAuthor"
        value={author}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disable}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};

AuthorField.propTypes = {
  authorLabel: PropTypes.string,
  authorName: PropTypes.string,
  styles: PropTypes.string,
  disable: PropTypes.bool,
  callKeyDown: PropTypes.func,
  callback: PropTypes.func,
};

export default AuthorField;

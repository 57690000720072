import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import PingService from "@services/pingService";
import VideoJS from "@shared/videoJS";
import SpinnerComp from "@conference/containers/spinner";

import {
  getStream,
  selectActiveStream,
  selectStreamLoading,
} from "@store/slices/streamsSlice";

import { selectShowPreview } from "@store/slices/metaSlice";

import { useSelector, useDispatch } from "react-redux";
import throttle from "lodash/throttle";

const useStyles = makeStyles({
  videoWrapper: {
    width: "100%",
    paddingTop: "56.25%",
    position: "relative",
  },
  videoBorder: {
    borderRadius: "8px",
    overflow: "hidden",
    boxShadow: "0 5px 5px rgba(182, 182, 182, 0.75)",
  },
  videoShadow: {
    backgroundColor: "rgba(43, 141, 210, 0.12);",
    padding: "18px",
    borderRadius: "8px",
  },
});

const pingService = new PingService();

const EventVideo = ({ forStream, statisticKey, onEnded, ping }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const playerRef = React.useRef(null);
  const stream = useSelector(selectActiveStream);
  const loading = useSelector(selectStreamLoading);
  const preview = useSelector(selectShowPreview);
  let videoJsOptions = {};

  const attendeeData = {
    locale: I18n.locale,
    device_type: Detectizr.device.type,
    screen_size: screen.width + "x" + screen.height,
    player_type: "video",
  };
  const attendeePing = throttle(() => {
    pingService
      .pingStream(statisticKey, attendeeData)
      .then(() => Util.log("Attendee ping sent"))
      .catch((err) => {
        Util.log("There was an error sending attendee ping:");
        Util.log(err);
      });
  }, 30000);

  useEffect(() => {
    dispatch(getStream({ streamId: forStream }));
  }, []);

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.on("loadedmetadata", () => {
      let textTracks = player.textTracks();
      let id3Track;

      for (let i = 0; i < textTracks.length; i++) {
        Util.log(textTracks[i].label);
        if (textTracks[i].label === "Timed Metadata") {
          id3Track = textTracks[i];
        }
      }

      id3Track.addEventListener("cuechange", () => {
        let activeCues = id3Track.activeCues;

        for (let i = 0; i < activeCues.length; i++) {
          let activeCue = activeCues[i];

          let decoder = new TextDecoder();
          let substring = activeCue.value.data.subarray(1, -1);
          let text = decoder.decode(substring);
          let now = new Date();
          let secToSrv = Math.round(now.getTime() / 1000 - parseFloat(text));
          Util.log("Currently: " + secToSrv + " seconds behind publisher");
        }
      });
    });

    player.on("ready", () => {
      let playlist;
      if (preview && stream.previewHlsPlayout)
        playlist = stream.previewHlsPlayout;
      else playlist = stream.hlsPlayout;

      player.src({
        src: playlist,
        type: "application/x-mpegURL",
      });
    });

    player.on("timeupdate", () => {
      {
        ping && attendeePing();
      }
    });

    player.on("ended", () => {
      Util.log("Video ended");
      onEnded();
    });
  };

  if (stream) {
    videoJsOptions = {
      // lookup the options in the docs for more options
      autoplay: true,
      controls: true,
      responsive: true,
      preferFullWindow: true,
      liveui: true,
      fluid: true,
      preload: "auto",
      sources: [],
      language: I18n.locale,
    };
  }

  return (
    <>
      {loading && <SpinnerComp />}
      {stream && (
        <div className={classes.videoShadow}>
          <div className={classes.videoBorder}>
            <VideoJS
              className={classes.videoWrapper}
              options={videoJsOptions}
              onReady={handlePlayerReady}
            />
          </div>
        </div>
      )}
    </>
  );
};

EventVideo.propTypes = {
  forStream: PropTypes.string,
  onEnded: PropTypes.func,
  statisticKey: PropTypes.string,
  ping: PropTypes.bool,
};

export default EventVideo;

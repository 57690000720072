import React from "react";
import PropTypes from "prop-types";

const SurveyTitle = ({ text }) => {
  return <h2 className="h4 m-b survey_name">{text}</h2>;
};

SurveyTitle.propTypes = {
  text: PropTypes.string,
};

export default SurveyTitle;

import React, { useState } from "react";
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import PropTypes from "prop-types";
import { selectActiveEvent } from "@store/slices/eventsSlice";
import { debounce } from "throttle-debounce";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  AutoImage,
  AutoLink,
  Autosave,
  BlockToolbar,
  Bold,
  Code,
  CodeBlock,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  GeneralHtmlSupport,
  Heading,
  Highlight,
  HtmlComment,
  HtmlEmbed,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  LinkImage,
  List,
  ListProperties,
  MediaEmbed,
  Paragraph,
  PasteFromOffice,
  RemoveFormat,
  SelectAll,
  ShowBlocks,
  SimpleUploadAdapter,
  SourceEditing,
  SpecialCharacters,
  SpecialCharactersArrows,
  SpecialCharactersCurrency,
  SpecialCharactersEssentials,
  SpecialCharactersLatin,
  SpecialCharactersMathematical,
  SpecialCharactersText,
  Strikethrough,
  Style,
  Subscript,
  Superscript,
  Table,
  TableCaption,
  TableColumnResize,
  TableToolbar,
  TextTransformation,
  Underline,
  Undo,
} from "ckeditor5";

import "ckeditor5/ckeditor5.css";
import "./editor.css";

import useHideInTestEnv from "@hooks/useHideInTestEnv";
import DataService from "@services/dataService";
import FloatingTagHolder from "@shared/tags/FloatingTagHolder";
import FadeOutComponent from "@shared/FadeOutComponent";
import SavedTag from "@shared/tags/SavedTag";
import ErrorTag from "@shared/tags/ErrorTag";

const dataService = new DataService();

const EventDescription = ({ editable }) => {
  const event = useSelector(selectActiveEvent);
  const [saving, setSaving] = useState(false);
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState(false);
  const { hideInTest } = useHideInTestEnv();

  const sendData = debounce(1000, (event, data) => {
    // setTouched(true) is needed to only show the saving tag when the user has
    // actually changed the description. Otherwise, the saving tag will show
    // when the page is loaded.
    setTouched(true);
    setSaving(true);
    const url = `/api/events/${event.id}/update_description`;
    if (data.description === event.description) return;
    dataService
      .sendData(url, data, "put")
      .then(() => {
        setSaving(false);
      })
      .catch(() => {
        setSaving(false);
        setError(true);
      });
  });
  const items = [
    "undo",
    "redo",
    "|",
    "sourceEditing",
    "showBlocks",
    "findAndReplace",
    "selectAll",
    "|",
    "heading",
    "style",
    "|",
    "fontSize",
    "fontFamily",
    "fontColor",
    "fontBackgroundColor",
    "|",
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "subscript",
    "superscript",
    "code",
    "removeFormat",
    "|",
    "specialCharacters",
    "link",
    "insertImage",
    "insertImageViaUrl",
    "mediaEmbed",
    "insertTable",
    "highlight",
    "codeBlock",
    "htmlEmbed",
    "|",
    "alignment",
    "|",
    "bulletedList",
    "numberedList",
    "outdent",
    "indent",
    "|",
    "accessibilityHelp",
  ];
  const plugins = [
    AccessibilityHelp,
    Alignment,
    AutoImage,
    AutoLink,
    Autosave,
    BlockToolbar,
    Bold,
    Code,
    CodeBlock,
    Essentials,
    FindAndReplace,
    FontBackgroundColor,
    FontColor,
    FontFamily,
    FontSize,
    GeneralHtmlSupport,
    Heading,
    Highlight,
    HtmlComment,
    HtmlEmbed,
    ImageBlock,
    ImageCaption,
    ImageInline,
    ImageInsert,
    ImageInsertViaUrl,
    ImageResize,
    ImageStyle,
    ImageTextAlternative,
    ImageToolbar,
    ImageUpload,
    Indent,
    IndentBlock,
    Italic,
    Link,
    LinkImage,
    List,
    ListProperties,
    MediaEmbed,
    Paragraph,
    PasteFromOffice,
    RemoveFormat,
    SelectAll,
    ShowBlocks,
    SimpleUploadAdapter,
    SourceEditing,
    SpecialCharacters,
    SpecialCharactersArrows,
    SpecialCharactersCurrency,
    SpecialCharactersEssentials,
    SpecialCharactersLatin,
    SpecialCharactersMathematical,
    SpecialCharactersText,
    Strikethrough,
    Style,
    Subscript,
    Superscript,
    Table,
    TableCaption,
    TableColumnResize,
    TableToolbar,
    TextTransformation,
    Underline,
    Undo,
  ];
  const blockToolbar = [
    "fontSize",
    "fontColor",
    "fontBackgroundColor",
    "|",
    "bold",
    "italic",
    "|",
    "link",
    "insertImage",
    "insertTable",
    "|",
    "bulletedList",
    "numberedList",
    "outdent",
    "indent",
  ];
  /**
   * @type {EditorConfig}
   */
  const config = {
    toolbar: {
      items,
      shouldNotGroupWhenFull: false,
    },
    ui: { poweredBy: { side: "left" } },
    plugins,
    blockToolbar: blockToolbar,
    fontFamily: { supportAllValues: true },
    initialData: event.description === "" ? undefined : event.description,
    htmlSupport: {
      allow: [
        {
          name: /^.*$/,
          styles: true,
          attributes: true,
          classes: true,
        },
      ],
    },
    image: {
      toolbar: [
        "toggleImageCaption",
        "imageTextAlternative",
        "|",
        "imageStyle:inline",
        "imageStyle:wrapText",
        "imageStyle:breakText",
        "|",
        "resizeImage",
      ],
    },
    menuBar: {
      isVisible: true,
    },
    table: {
      contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
  };

  if (editable) {
    return (
      <div className="description-editor">
        {hideInTest(
          <CKEditor
            editor={ClassicEditor}
            config={{ ...config }}
            onChange={(_, editor) => {
              const data = { data: editor.getData(), locale: I18n.locale };
              sendData(event, data);
            }}
          />,
        )}
        <FloatingTagHolder>
          <FadeOutComponent show={touched && !saving} time={700}>
            {error ? <ErrorTag /> : <SavedTag />}
          </FadeOutComponent>
        </FloatingTagHolder>
      </div>
    );
  } else {
    return ReactHtmlParser(event.description);
  }
};

EventDescription.propTypes = {
  editable: PropTypes.bool,
};

export default EventDescription;

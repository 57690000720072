import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectShowQA, selectShowPubQA } from "@store/selectors/activeEvent";

import { selectLoading } from "@store/slices/eventsSlice";

import {
  getQuestionRooms,
  selectSelectedRoom,
  selectFirstActiveChatRoom,
  setSelectedRoom,
} from "@store/slices/questionRoomsSlice";

import {
  subscribeToQuestionRooms,
  unsubscribeFromQuestionRooms,
} from "@store/middleware/websocketMiddleware";

import SpinnerComp from "@conference/containers/spinner";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import FormQuestion from "./formQuestion";
import QuestionsList from "./QuestionsList";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: "rgb(113, 113, 113)",
    width: "100%",
    height: "96%",
    // TODO: SSY-2044 set width by a shared root component
    maxWidth: 2665,
  },
  qaListContainer: {
    maxHeight: 500,
    minHeight: 200,
    position: "relative",
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(2),
    },
  },
  qaFormContainer: {
    maxWidth: 1330,
  },
  qaList: {
    flex: "auto",
    height: "70%",
  },
}));

const EventQuestions = ({ horizontal = false, forEventId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const showList = useSelector(selectShowPubQA);
  const showQA = useSelector(selectShowQA);
  const loading = useSelector(selectLoading);
  const selectedQuestionRoom = useSelector(selectSelectedRoom);
  const firstActiveRoom = useSelector(selectFirstActiveChatRoom);

  useEffect(() => {
    dispatch(getQuestionRooms({ eventId: forEventId }));
    dispatch(subscribeToQuestionRooms(forEventId));
    return () => {
      dispatch(unsubscribeFromQuestionRooms(forEventId));
    };
  }, []);

  useEffect(() => {
    if (!selectedQuestionRoom || !selectedQuestionRoom.active) {
      firstActiveRoom && dispatch(setSelectedRoom(firstActiveRoom.id));
    }
  }, [selectedQuestionRoom, firstActiveRoom]);

  const verticalStyle = (
    <Grid container justifyContent="center">
      <Grid item id="questions-row" className={classes.qaFormContainer} xs>
        <FormQuestion />
      </Grid>
      {showList && (
        <Grid
          item
          xs={12}
          md={7}
          id="questions-list"
          className={classes.qaListContainer}
        >
          <QuestionsList />
        </Grid>
      )}
    </Grid>
  );

  const horizontalStyle = (
    <Grid container direction="column" style={{ height: "100%" }}>
      <Grid item id="questions-row">
        <FormQuestion horizontal={horizontal} />
      </Grid>
      {showList && (
        <Grid item id="questions-list" className={classes.qaList}>
          <QuestionsList />
        </Grid>
      )}
    </Grid>
  );

  return (
    <div className={classes.root}>
      {loading && <SpinnerComp />}
      {showQA && (horizontal ? horizontalStyle : verticalStyle)}
    </div>
  );
};

EventQuestions.propTypes = {
  horizontal: PropTypes.bool,
  forEventId: PropTypes.string,
};

export default EventQuestions;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import DataService from "@services/dataService";
const dataService = new DataService();

const getCountAttendees = createAsyncThunk(
  "attendees/getCountAttendees",
  async (eventId) => {
    const response = await dataService.getData(
      `/api/events/${eventId}/current_attendee_count`,
    );
    return response.data.current_attendee_count;
  },
);

export const countAttendeesSlice = createSlice({
  name: "countAttendees",
  initialState: { count: 0, loading: false, error: "" },
  reducers: {},
  extraReducers: {
    [getCountAttendees.pending]: (state) => {
      if (!state.loading && !state.count) {
        state.loading = true;
      }
    },
    [getCountAttendees.fulfilled]: (state, action) => {
      state.loading = false;
      state.count = action.payload;
    },
    [getCountAttendees.rejected]: (state, action) => {
      state.loading = false;
      state.count = 0;
      state.error = action.error;
    },
  },
});

export const selectCountAttendees = (state) => {
  return state.countAttendees.count;
};

export const selectLoading = (state) => {
  return state.countAttendees.loading;
};

export { getCountAttendees };
export default countAttendeesSlice.reducer;

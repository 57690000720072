/** THIS FILE IS DEPRECATED PLEASE USE @shared/Buttons/SubmitButton */
/* eslint-disable quote-props */
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  root: {
    textTransform: "none",
    fontSize: "1.2rem",
    maxHeight: 31,
    color: "#fff !important",
    backgroundColor: "#594f8d",
    borderColor: "#594f8d",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#594f8d",
      borderColor: "#594f8d",
      boxShadow: "none",
    },
    "&:focus": {
      color: "#fff",
      outline: "none",
    },
  },
});

const SubmitButton = (props) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      className={classes.root}
      {...props}
      type="submit"
    >
      {I18n.t("submit")}
    </Button>
  );
};

SubmitButton.propTypes = {
  props: PropTypes.object,
};

export default SubmitButton;

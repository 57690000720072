import axios from "axios";

export default class DataService {
  getData = (url, jwt = false, params = {}) => {
    const headers = {
      Accept: "application/json",
    };
    if (jwt) {
      headers["Authorization"] = "Bearer " + jwt;
    }

    return axios.request({
      url,
      method: "get",
      withCredentials: true,
      headers: headers,
      params: params,
    });
  };

  postData = (url, data, jwt = false) => {
    return this.sendData(url, data, "post", jwt);
  };

  putData = (url, data, jwt = false) => {
    return this.sendData(url, data, "put", jwt);
  };

  sendData = (url, data, method, jwt = false) => {
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    const headers = {
      Accept: "application/json",
      "X-CSRF-Token": `${token}`,
    };
    if (jwt) {
      headers["Authorization"] = "Bearer " + jwt;
    }

    return axios.request({
      url,
      method: method,
      withCredentials: true,
      headers: headers,
      data,
    });
  };
}

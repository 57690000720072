import React, { useEffect, useState } from "react";
import {
  FirstColumn,
  SecondColumn,
  RowStyle,
} from "./SurveyChartCardStyles/SurveyChartCardStyles";
import PropTypes from "prop-types";

import { HI_CONSTRAST_COLOR } from "@shared/colors";

import { useSelector, useDispatch } from "react-redux";

import useInterval from "@components/hooks/useInterval";

import PieChartGraph from "@shared/charts/pieChart/pieChart";
import SurveyChartData from "./SurveyChartData";
import SurveyCardFooter from "../SurveyCardFooter";
import {
  getSurveyOptionAnswers,
  selectAllAnswersFromOptions,
} from "@store/slices/survey/surveysAnswersSlice";

const SurveyChartCard = ({ options }) => {
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(-1);
  const optionAnswers = useSelector((state) =>
    selectAllAnswersFromOptions(state, options),
  );

  const total = optionAnswers.reduce(
    (acc, answers) => acc + answers?.length,
    0,
  );

  useEffect(() => {
    options.forEach((option) => {
      dispatch(getSurveyOptionAnswers({ optionId: option.id }));
    });
  }, []);

  useInterval(() => {
    options.forEach((option) => {
      dispatch(getSurveyOptionAnswers({ optionId: option.id }));
    });
  }, 15000);

  const data = options.map((option, index) => {
    let obj = { ...option };
    obj["name"] = option.text;
    obj["percent"] =
      total == 0 ? 0 : (optionAnswers[index]?.length / total) * 100;
    obj["value"] = optionAnswers[index]?.length;
    obj["color"] = HI_CONSTRAST_COLOR[index % HI_CONSTRAST_COLOR.length];
    return obj;
  });

  return (
    <RowStyle>
      <FirstColumn>
        <div className="survey-text-container">
          <SurveyChartData
            data={data}
            setActiveIndex={setActiveIndex}
            activeIndex={activeIndex}
          />
          <SurveyCardFooter total={total} />
        </div>
      </FirstColumn>
      <SecondColumn>
        <PieChartGraph
          data={data}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          height={200}
        />
      </SecondColumn>
    </RowStyle>
  );
};

SurveyChartCard.propTypes = {
  options: PropTypes.array,
};

export default SurveyChartCard;

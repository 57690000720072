import React from "react";
import PropTypes from "prop-types";

import { Field } from "formik";

const SurveyTextInput = ({ question }) => {
  const option = question.options[0];

  return (
    <>
      <Field
        className="form-control input-sm"
        name={question.id}
        placeholder={option.text}
      />
    </>
  );
};
SurveyTextInput.propTypes = {
  question: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        text: PropTypes.string,
      }),
    ),
    id: PropTypes.number,
  }),
};

export default SurveyTextInput;

/* global I18n */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  boxText: {
    fontSize: "12px",
    padding: "5px 10px",
    resize: "vertical",
    maxHeight: "344px",
  },
  labelContainer: {
    minWidth: 120,
  },
  spanFont: {
    fontSize: "1.2rem !important",
    color: "#afafaf",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1rem !important",
    },
  },
  spanContainer: {
    textAlign: "end",
    [theme.breakpoints.down(300)]: {
      textAlign: "start",
    },
  },
}));

const TextField = React.forwardRef(
  (
    {
      textLabel,
      textPlaceholder,
      text,
      styles,
      lengthValue,
      disable,
      callback,
    },
    ref,
  ) => {
    const classes = useStyles();
    const [textQuestion, setTextQuestion] = useState("");
    const [length, setLength] = useState(0);

    useEffect(() => {
      setTextQuestion(text);
      if (text === "") {
        setLength(lengthValue);
      }
    }, [text]);

    const handleChange = (e) => {
      const value = e.target.value;
      setTextQuestion(value);
      setLength(lengthValue - value.length);
    };

    const handleBlur = () => {
      callback(textQuestion);
    };

    return (
      <Grid className={"form-group " + classes.root}>
        <Grid item container xs={12}>
          <Grid item className={classes.labelContainer} xs={5}>
            <label> {textLabel} </label>
          </Grid>

          <Grid item className={classes.spanContainer} xs={7}>
            <span className={classes.spanFont}>
              {I18n.t("questions.message_hint", { num: `${length}` })}
            </span>
          </Grid>
        </Grid>

        <Grid>
          <textarea
            className={`form-control ${classes.boxText} ${styles}`}
            rows="3"
            maxLength={lengthValue}
            placeholder={textPlaceholder}
            value={textQuestion}
            onChange={handleChange}
            ref={ref}
            onBlur={handleBlur}
            disabled={disable}
          />
        </Grid>
      </Grid>
    );
  },
);

TextField.propTypes = {
  textLabel: PropTypes.string,
  textPlaceholder: PropTypes.string,
  text: PropTypes.string,
  lengthValue: PropTypes.number,
  styles: PropTypes.string,
  length: PropTypes.string,
  disable: PropTypes.bool,
  callback: PropTypes.func,
};

TextField.displayName = "TextField";
export default TextField;

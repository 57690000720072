import React from "react";
import PropTypes from "prop-types";

import {
  SurveySelectInput,
  SurveyTextInput,
  SurveyTextareaInput,
} from "./SurveyInputs";
import { Box } from "@material-ui/core";
import SurveySection from "./SurveySection";

const SurveyQuestionInput = ({ question, questionNumber, error }) => {
  const type = question.options[0].type;
  const textArea = type === "Survey::Option::Textarea";
  const textField = type === "Survey::Option::Textfield";

  let input;
  if (question.options.length < 1) {
    return <Box mt={3}>{I18n.t("js-error-not-logged")}</Box>;
  }

  if (question.options[0].is_text && textField) {
    input = <SurveyTextInput question={question} />;
  } else if (question.options[0].is_text && textArea) {
    input = <SurveyTextareaInput question={question} />;
  } else {
    input = <SurveySelectInput question={question} />;
  }

  return (
    <SurveySection
      title={question.text}
      number={questionNumber}
      mandatory={question.mandatory}
      error={error}
    >
      {input}
    </SurveySection>
  );
};

SurveyQuestionInput.propTypes = {
  question: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        text: PropTypes.string,
        is_text: PropTypes.bool,
        type: PropTypes.string,
      }),
    ),
    id: PropTypes.number,
    text: PropTypes.string,
    mandatory: PropTypes.bool,
  }),
  questionNumber: PropTypes.number,
  error: PropTypes.string,
};

export default SurveyQuestionInput;

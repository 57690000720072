import { withStyles } from "@material-ui/core/styles";
import { LinearProgress } from "@material-ui/core";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    width: "100%",
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[100],
  },
  bar: (props) => ({
    borderRadius: 5,
    backgroundColor: props.barcolor,
  }),
}))(LinearProgress);

export default BorderLinearProgress;

import React, { useEffect } from "react";
import PropTypes from "prop-types";

import useInterval from "@components/hooks/useInterval.jsx";
import CountdownProgressBar from "@shared/CountdownProgressBar/CountdownProgressBar";

import { useSelector, useDispatch } from "react-redux";
import {
  updateCountdownTimeLeft,
  getCountdownData,
  selectCountdownTimeLeftPercentage,
  selectCountdownTimeLeft,
  selectCountdownMessage,
  selectCountdownActive,
} from "@store/slices/countdownSlice";

import { subscribeToCountdown } from "@store/middleware/websocketMiddleware";

const EventCountdownBar = ({ eventId }) => {
  let color;
  const dispatch = useDispatch();
  const timeLeft = useSelector(selectCountdownTimeLeft);
  const timeLeftPercentage = useSelector(selectCountdownTimeLeftPercentage);
  const message = useSelector(selectCountdownMessage);
  const active = useSelector(selectCountdownActive) || false;

  const width = `${timeLeftPercentage}%`;

  const duration = new Date(timeLeft).toISOString().substring(11, 19);

  useInterval(() => {
    dispatch(updateCountdownTimeLeft(new Date().valueOf()));
  }, 1000);

  useEffect(() => {
    dispatch(subscribeToCountdown(eventId));
    dispatch(getCountdownData(eventId));
  }, []);

  switch (true) {
    case timeLeftPercentage > 50:
      color = "#92CF5C";
      break;
    case timeLeftPercentage <= 50 && timeLeftPercentage > 25:
      color = "#FCC44D";
      break;
    case timeLeftPercentage <= 25 && timeLeftPercentage > 0:
      color = "#FB6B5B";
      break;
    default:
      color = "#DADADA";
  }

  return (
    <>
      {active && (
        <CountdownProgressBar
          color={color}
          width={width}
          duration={duration}
          message={message}
        />
      )}
    </>
  );
};

EventCountdownBar.propTypes = {
  eventId: PropTypes.string,
};

export default EventCountdownBar;

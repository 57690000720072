import React from "react";
import PropTypes from "prop-types";

import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { selectActiveEvent } from "@store/slices/eventsSlice";
import { useSelector } from "react-redux";
import EventLogo from "./eventLogo";

const useStyles = makeStyles({
  wrapper: {},
  eventTitle: {
    marginTop: "10px",
    fontWeight: "900",
    color: "black",
  },
  eventHour: {
    color: "#9A9595",
  },
});

const EventTitle = ({ withLogo }) => {
  const classes = useStyles();
  const event = useSelector(selectActiveEvent);

  return (
    <Grid
      container
      spacing={2}
      className={classes.wrapper}
      alignItems="center"
      justifyContent={withLogo ? "center" : "flex-start"}
    >
      <Grid item>{withLogo && <EventLogo />}</Grid>
      <Grid item>
        <Grid container direction="column">
          <Grid item xs>
            <h1 className={classes.eventTitle}>{event.title}</h1>
          </Grid>
          <Grid item xs>
            <h4
              className={classes.eventHour}
              dangerouslySetInnerHTML={{ __html: event.custom_event_date_time }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

EventTitle.propTypes = {
  withLogo: PropTypes.bool,
};

export default React.memo(EventTitle);

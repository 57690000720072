import React from "react";
import PropTypes from "prop-types";

import { Field } from "formik";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "inline-block",
    maxWidth: "100%",
    marginBottom: "5px",
  },
  radio: {
    margin: "4px 0 0",
  },
  optionLabel: {
    marginLeft: "10px",
    fontWeight: "normal",
  },
}));

const SurveySelectInput = ({ question }) => {
  const classes = useStyles();

  return (
    <>
      {question.options.map((option) => {
        return (
          <div className="block" key={option.id}>
            <label className={classes.wrapper}>
              <Field
                type="radio"
                name={question.id}
                value={option.id.toString()}
                className={classes.radio}
              />
              <span className={classes.optionLabel}>{option.text}</span>
            </label>
          </div>
        );
      })}
    </>
  );
};

SurveySelectInput.propTypes = {
  question: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        text: PropTypes.string,
      }),
    ),
    id: PropTypes.number,
  }),
};

export default SurveySelectInput;

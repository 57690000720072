/**
 * - use this hook to wrap components that are incompatible with Jest
 * - will return a wrapper function that can be used to handle the icompatible node
 *
 * @returns {{ hideInTest: (node: ReactNode) => ReactNode|null }}
 */

const useHideInTestEnv = () => {
  /**
   * @type {true|undefined}
   */
  const runningInTestEnv = Util.inJest;

  return {
    hideInTest: (node) => (runningInTestEnv ? null : node),
  };
};

export default useHideInTestEnv;

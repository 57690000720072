/* global Util */
import WebSocketService from "@services/webSocketService";
import { updateEvent } from "../slices/eventsSlice";
import { moderateDevices } from "../slices/conferenceSlice";
import {
  addQuestionRoom,
  removeQuestionRoom,
  updateQuestionRoom,
  updateQuestionRoomOrderPartially,
} from "../slices/questionRoomsSlice";
import { addQuestion, updateQuestion } from "../slices/questionsSlice";
import { updateCountdownOnClient } from "../slices/countdownSlice";
import { updateIframe, addIframe } from "../slices/iframesSlice";
import { addStream } from "../slices/streamsSlice";

// action factories
export const subscribeToEventState = (eventId) => {
  return {
    type: "WS_SUBSCRIBE_TO_EVENT_STATE",
    payload: eventId,
  };
};
//__NOTE: as mentioned in the middleware, this should not be called anymore and as of now is not used in any react code
export const unsubscribeFromEventState = (eventId) => {
  return {
    type: "WS_UNSUBSCRIBE_FROM_EVENT_STATE",
    payload: eventId,
  };
};

export const subscribeToQuestionRooms = (eventId) => {
  return {
    type: "WS_SUBSCRIBE_TO_QUESTION_ROOMS",
    payload: eventId,
  };
};

export const subscribeToStreams = (eventId) => {
  return {
    type: "WS_SUBSCRIBE_TO_STREAMS",
    payload: eventId,
  };
};

export const unsubscribeFromStreams = (eventId) => {
  return {
    type: "WS_UNSUBSCRIBE_FROM_STREAMS",
    payload: eventId,
  };
};

export const unsubscribeFromQuestionRooms = (eventId) => {
  return {
    type: "WS_UNSUBSCRIBE_FROM_QUESTION_ROOMS",
    payload: eventId,
  };
};

export const unsubscribeToStreams = (eventId) => {
  return {
    type: "WS_UNSUBSCRIBE_TO_STREAMS",
    payload: eventId,
  };
};

export const subscribeToIframes = (eventId) => {
  return {
    type: "WS_SUBSCRIBE_TO_IFRAMES",
    payload: eventId,
  };
};

export const subscribeToQuestionRoomChat = (eventId, roomId) => {
  return {
    type: "WS_SUBSCRIBE_TO_QUESTION_ROOM_CHAT",
    payload: { eventId, roomId },
  };
};

export const unsubscribeFromQuestionRoomChat = (eventId, roomId) => {
  return {
    type: "WS_UNSUBSCRIBE_TO_QUESTION_ROOM_CHAT",
    payload: { eventId, roomId },
  };
};

export const subscribeToQuestionsRoomOrder = (roomId) => {
  return {
    type: "WS_SUBSCRIBE_TO_CHAT_ROOM_ORDER",
    payload: { roomId },
  };
};

export const unsubscribeFromQuestionsRoomOrder = (roomId) => {
  return {
    type: "WS_UNSUBSCRIBE_FROM_CHAT_ROOM_ORDER",
    payload: { roomId },
  };
};

export const subscribeToCountdown = (eventId) => {
  return {
    type: "WS_SUBSCRIBE_TO_COUNTDOWN",
    payload: eventId,
  };
};

export const unsubscribeToCountdown = (eventId) => {
  return {
    type: "WS_UNSUBSCRIBE_TO_COUNTDOWN",
    payload: eventId,
  };
};

export const publishUpdateQuestion = (question) => {
  return {
    type: "WS_UPDATE_QUESTION",
    payload: question,
  };
};

export const subscribeToConferenceListener = (eventId) => {
  return {
    type: "WS_SUBSCRIBE_TO_CONFERENCE",
    payload: eventId,
  };
};

export const unsubscribeFromConferenceListener = (eventId) => {
  return {
    type: "WS_UNSUBSCRIBE_FROM_CONFERENCE",
    payload: eventId,
  };
};

export const sendModerationMessage = (message) => {
  return {
    type: "WS_SEND_MODERATION_MESSAGE",
    payload: message,
  };
};

// Custom middleware
// Outer function:
export const socketMiddleware = (storeAPI) => {
  const websocket = new WebSocketService();
  Util.log("websocket initilized");

  // wrapDispatch:
  return (next) => {
    // handleAction:
    return (action) => {
      if (!action.type) return next(action);
      // flag
      if (action.type === "WS_SUBSCRIBE_TO_EVENT_STATE") {
        Util.log("Subscribed to event state channel: " + action.payload);
        const subObject = websocket.subscribeToEventStateChange(
          action.payload,
          (_message) => {
            let message = Object.assign({}, _message);
            if (message.method === "update") {
              storeAPI.dispatch(
                updateEvent({ id: action.payload, changes: message.event }),
              );
            }
            Util.log(_message);
            const isBackend = storeAPI.getState().meta.backendView;
            if (!isBackend) {
              if (message.state) {
                const delay = Util.triangularRand(18000, 30000, 28000);
                Util.log("State delay is delayed by: " + delay + " seconds");
                setTimeout(() => {
                  Util.log("Update State: ");
                  Util.log(_message);
                  storeAPI.dispatch(
                    updateEvent({ id: action.payload, changes: _message }),
                  );
                }, delay);
              }
              delete message.state;
            }
            Util.log("Event state update received", message);
            // Dont update the state immediately. This is handles separately to respect stream delays.
            storeAPI.dispatch(
              updateEvent({ id: action.payload, changes: message }),
            );
          },
        );
        // further elevates the subscription object (as a promise) from faye to the caller in the webSocketService
        return subObject;
      }

      if (action.type === "WS_UNSUBSCRIBE_FROM_EVENT_STATE") {
        //__NOTE: in theore after patches from SSY-2846 this should be not called in any react code anymore
        Util.log("Unsubscribe from event: " + action.payload);
        websocket.unsubscribeFromEventStateChange(action.payload);
      }

      if (action.type === "WS_SUBSCRIBE_TO_QUESTION_ROOMS") {
        Util.log("Subscribe to Question Rooms updates");
        websocket.subscribeToQuestionRooms(action.payload, (obj) => {
          const chat_room = obj.chat_room;
          if (obj.method === "update") {
            Util.log("Update Question Room: ", chat_room);
            storeAPI.dispatch(
              updateQuestionRoom({ id: chat_room.id, changes: chat_room }),
            );
          }
          if (obj.method === "create") {
            storeAPI.dispatch(addQuestionRoom(chat_room));
          }
          if (obj.method === "destroy") {
            storeAPI.dispatch(removeQuestionRoom(chat_room.id));
          }
        });
      }

      if (action.type === "WS_SUBSCRIBE_TO_CHAT_ROOM_ORDER") {
        Util.log("Subscribe to Question Room chat: " + action.payload.roomId);
        websocket.subscribeToQuestionsRoomOrder(
          action.payload.roomId,
          (obj) => {
            Util.log("Chat room order update received", obj);
            const chat_room = obj.chat_room;
            if (obj.method === "order_update") {
              storeAPI.dispatch(
                updateQuestionRoomOrderPartially({
                  id: chat_room.id,
                  chat_room: chat_room,
                }),
              );
            }
          },
        );
      }

      if (action.type === "WS_SUBSCRIBE_TO_STREAMS") {
        Util.log("Subscribe to Streams updates");
        websocket.subscribeToStreams(action.payload, (obj) => {
          const stream = obj.stream;
          if (obj.method === "create") {
            storeAPI.dispatch(addStream(stream));
          }
        });
      }

      if (action.type === "WS_UNSUBSCRIBE_FROM_CHAT_ROOM_ORDER") {
        Util.log("Unsubscribe from chat room order: " + action.payload.roomId);
        websocket.unsubscribeFromQuestionsRoomOrder(action.payload.roomId);
      }

      if (action.type === "WS_UNSUBSCRIBE_FROM_STREAMS") {
        Util.log("Unsubscribe from streams: " + action.payload);
        websocket.unsubscribeFromStreams(action.payload);
      }

      if (action.type === "WS_SUBSCRIBE_TO_IFRAMES") {
        Util.log("Subscribe to Iframe updates");
        websocket.subscribeToIframes(action.payload, (obj) => {
          const iframe = obj.iframe;

          if (obj.method === "update") {
            storeAPI.dispatch(updateIframe({ id: iframe.id, changes: iframe }));
          }
          if (obj.method === "create") {
            storeAPI.dispatch(addIframe(iframe));
          }
        });
      }

      if (action.type === "WS_SUBSCRIBE_TO_QUESTION_ROOM_CHAT") {
        Util.log("Subscribe to Question Room chat: " + action.payload.roomId);
        websocket.subscribeToQuestionRoomChat(
          action.payload.roomId,
          action.payload.eventId,
          (obj) => {
            if (obj.method === "update") {
              storeAPI.dispatch(
                updateQuestion({ id: obj.question.id, changes: obj.question }),
              );
            }
            if (obj.method === "create") {
              storeAPI.dispatch(addQuestion(obj.question));
            }
          },
        );
      }

      if (action.type === "WS_UNSUBSCRIBE_FROM_EVENT_QUESTION_CHAT") {
        websocket.unsubscribeFromQuestionRoomChat(
          action.payload.eventId,
          action.payload.roomId,
        );
      }

      if (action.type === "WS_UNSUBSCRIBE_FROM_QUESTION_ROOMS") {
        Util.log("Unsubscribe from chat rooms: " + action.payload);
        websocket.unsubscribeFromQuestionRooms(action.payload);
      }

      if (action.type === "WS_SUBSCRIBE_TO_COUNTDOWN") {
        Util.log("Subscribe to Countdown updates");
        websocket.subscribeToCountdown(action.payload, (obj) => {
          const countdown = obj.countdown;
          if (obj.method === "update") {
            storeAPI.dispatch(updateCountdownOnClient(countdown));
          }
        });
      }

      if (action.type === "WS_UNSUBSCRIBE_FROM_COUNTDOWN") {
        Util.log("Unsubscribe from countdown: " + action.payload);
        websocket.unsubscribeFromCountdown(action.payload);
      }

      if (action.type === "WS_SUBSCRIBE_TO_CONFERENCE") {
        Util.log("Subscribed to conference channel: " + action.payload);
        websocket.subscribeSocketsConference(action.payload, (message) => {
          if (message.constructor === Object) {
            Util.log("Conference message received", message);
            storeAPI.dispatch(moderateDevices(message));
          }
        });
      }

      if (action.type === "WS_UNSUBSCRIBE_FROM_CONFERENCE") {
        Util.log("Unsubscribe from event: " + action.payload);
        websocket.unsubscribeSocketsConference(action.payload);
      }

      if (action.type === "WS_SEND_MODERATION_MESSAGE") {
        Util.log("Send moderation message: ", action.payload);
        websocket.sendModerationMessage(action.payload);
      }
      // call next middleware or store.dispatch if this is the last one
      return next(action);
    };
  };
};

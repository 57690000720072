import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  section: {
    marginLeft: "46px",
  },
  questionNum: {
    color: "#fff",
    fontWeight: "bold",
  },
  bulletPoint: {
    marginTop: "-3px",
    marginLeft: "5px",
    float: "left",
  },
  article: {
    paddingBottom: "10px",
  },
}));

const displayTitle = (title, mandatory) => {
  if (mandatory) {
    return (
      <>
        <span>{title}</span>
        &nbsp;
        <span className="text-danger">*</span>
      </>
    );
  } else {
    return title;
  }
};

const SurveySection = ({ title, mandatory, number, error, children }) => {
  const classes = useStyles();

  return (
    <>
      <article className={classes.article}>
        <div className={"fa-stack " + classes.bulletPoint}>
          <i className="fa fa-circle text-info fa-stack-2x"></i>
          <p className={`fa-stack-1x ${classes.questionNum}`}>{number}</p>
        </div>
        <section className={classes.section}>
          <header>{displayTitle(title, mandatory)}</header>
          <div className="text-danger"> {error} </div>
        </section>
      </article>
      <article className={classes.article}>
        <section className={classes.section}>{children}</section>
      </article>
    </>
  );
};

SurveySection.propTypes = {
  children: PropTypes.node,
  number: PropTypes.number,
  title: PropTypes.string,
  mandatory: PropTypes.bool,
  error: PropTypes.string,
};

export default SurveySection;

import React from "react";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import ReactWordcloud from "react-wordcloud";
import { HI_CONSTRAST_COLOR } from "@shared/colors";

const useStyles = makeStyles({
  svgWidth: {
    maxWidth: "100%",
  },
});

const WordsCloudComponent = ({ words }) => {
  const classes = useStyles();
  let minFontSize;
  let maxFontSize;

  switch (true) {
    case screen.width < 320:
      maxFontSize = 10;
      minFontSize = 10;
      break;
    case screen.width < 450:
      maxFontSize = 15;
      minFontSize = 15;
      break;
    case screen.width > 450:
      maxFontSize = 55;
      minFontSize = 20;
      break;
  }

  const wordsCloudOptions = {
    enableTooltip: true,
    enableOptimizations: true,
    colors: HI_CONSTRAST_COLOR,
    deterministic: true,
    fontFamily: "Open Sans",
    fontSizes: [minFontSize, maxFontSize],
    rotations: 2,
    rotationAngles: [0, 90],
    svgAttributes: {
      class: classes.svgWidth,
    },
  };

  const callbacks = {
    getWordTooltip: (word) => `${word.tooltip} (${word.value})`,
  };

  // This is a workaround for the fact that the wordcloud library
  // doesn't work in Jest. It's not a problem for the app itself
  const runningInTestEnv = Util.inJest;

  return (
    <div
      className="words-cloud-component"
      style={{ margin: "0 auto", height: "500px" }}
    >
      {!runningInTestEnv && (
        <ReactWordcloud
          words={words}
          options={wordsCloudOptions}
          callbacks={callbacks}
        />
      )}
    </div>
  );
};

WordsCloudComponent.propTypes = {
  words: PropTypes.array.isRequired,
};

const WordsCloudMemoized = React.memo(
  WordsCloudComponent,
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
  },
);

const truncateString = (string = "", maxLength = 12) =>
  string.length > maxLength ? `${string.substring(0, maxLength)}…` : string;

const WordsCloud = ({ answers }) => {
  let wordMap = {};
  let words = [];
  let tooltipMap = {};

  answers.forEach((answer) => {
    const fullAnswer = answer.input_value;
    if (fullAnswer.length > 0) {
      wordMap[fullAnswer] ||= 0;
      wordMap[fullAnswer] += 1;
      tooltipMap[fullAnswer] = fullAnswer;
    }
  });
  for (const [key, value] of Object.entries(wordMap)) {
    let entry = {};
    entry[key] = value;
    words.push({
      text: truncateString(key),
      value: truncateString(value),
      tooltip: tooltipMap[key],
    });
  }
  return <WordsCloudMemoized words={words} />;
};

WordsCloud.propTypes = {
  answers: PropTypes.array.isRequired,
};

export default WordsCloud;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Paper, Box } from "@material-ui/core";

export const useStyles = makeStyles({
  surveyTextTitle: {
    fontWeight: "900",
    margin: "0px",
    padding: "20px 0px 0px 10px",
  },
  content: {
    paddingLeft: "10px",
    fontFamily: "Open Sans",
    fontSize: "14px",
  },
});

const WidgetCard = ({ head, body, smallMargin }) => {
  const classes = useStyles();

  return (
    <Box style={{ margin: smallMargin ? "1em 0.2em" : "1em" }}>
      <Paper elevation={3} style={{ padding: "10px 20px" }}>
        {head && (
          <>
            <h4 className={classes.surveyTextTitle}>{head}</h4>
            <hr />
          </>
        )}
        <div className={classes.content}>{body}</div>
      </Paper>
    </Box>
  );
};

WidgetCard.propTypes = {
  head: PropTypes.node,
  body: PropTypes.node,
  smallMargin: PropTypes.bool,
};

export default WidgetCard;

/* global Util */

import {
  selectSortByVotes,
  updateQuestion,
} from "@store/slices/questionsSlice";

import {
  updateQuestionRoomOrderLocal,
  selectSelectedRoom,
  moveQuestionOnServer,
} from "@store/slices/questionRoomsSlice";

// action factories
export const updateAndReorderQuestion = (message) => {
  return {
    type: "UPDATE_AND_REORDER_QUETIONS",
    payload: message,
  };
};

export const trashQuestion = (question) => {
  return {
    type: "TRASH_QUESTION",
    payload: {
      id: question.id,
      fromState: question.state.toString(),
    },
  };
};

// Custom middleware
// Outer function:
export const questionsOrderMiddleware = (storeAPI) => {
  // wrapDispatch:
  return (next) => {
    // handleAction:
    return (action) => {
      if (!action.type) return next(action);
      /**
       * @param {Object} options - The options object.
       * @param {boolean} options.resetEditor - A boolean indicating whether to reset the editor or not.
       */
      const updateQuestionLocalAndServer = (
        chatRoomId,
        newStateOrderMaps,
        questionId,
        newState,
        newIndex,
        options,
      ) => {
        const updateQuestionPayload = {
          id: questionId,
          changes: {
            state: parseInt(newState),
          },
        };
        if (options.resetEditor) {
          updateQuestionPayload.changes.editor_id = null;
        }

        storeAPI.dispatch(updateQuestion(updateQuestionPayload));

        storeAPI.dispatch(
          updateQuestionRoomOrderLocal({
            id: chatRoomId,
            newStateOrderMaps: newStateOrderMaps,
          }),
        );

        storeAPI.dispatch(
          moveQuestionOnServer({
            chatRoomId: chatRoomId,
            question_id: questionId,
            new_state: newState,
            new_index: newIndex,
            reset_editor: options.resetEditor || false,
          }),
        );
      };

      if (action.type === "TRASH_QUESTION") {
        // get current order map
        const currentRoom = selectSelectedRoom(storeAPI.getState());
        const oldStateMaps = currentRoom.order_state_map;
        const id = action.payload.id;

        let sourceStateMap = [...oldStateMaps[action.payload.fromState]];
        sourceStateMap = sourceStateMap.filter((q) => q != id);

        let trashMap = [...(oldStateMaps["400"] || [])];
        trashMap.push(id);

        const newStateOrderMaps = [
          {
            state: action.payload.fromState,
            newMap: sourceStateMap,
          },
          {
            state: "400",
            newMap: trashMap,
          },
        ];

        updateQuestionLocalAndServer(
          currentRoom.id,
          newStateOrderMaps,
          id,
          "400",
          trashMap.length - 1,
          { resetEditor: true },
        );
      }

      if (action.type === "UPDATE_AND_REORDER_QUETIONS") {
        // reordering orientated on example implementation:
        // https://github.com/atlassian/react-beautiful-dnd/blob/master/stories/src/reorder.js

        // get current order map
        const currentRoom = selectSelectedRoom(storeAPI.getState());
        const oldStateMaps = currentRoom.order_state_map;
        const sortByVotes = selectSortByVotes(storeAPI.getState());

        const { id, source, destination } = action.payload;

        // no ordering when sorting by votes in same column
        if (source.droppableId === destination.droppableId && sortByVotes)
          return next(action);

        let sourceStateMap = [...oldStateMaps[source.droppableId]];

        // remove from current list
        let removed;
        if (sortByVotes)
          [removed] = sourceStateMap.splice(sourceStateMap.indexOf(id), 1);
        else [removed] = sourceStateMap.splice(source.index, 1);

        // removed need to be the id now
        if (removed != id) {
          Util.log("Removed question was not in state, adding now");
          removed = id;
        }

        let newStateOrderMaps = [];
        if (source.droppableId === destination.droppableId) {
          sourceStateMap.splice(destination.index, 0, removed);
          newStateOrderMaps = [
            {
              state: source.droppableId,
              newMap: sourceStateMap,
            },
          ];
        } else {
          // destination is a different
          let destinationStateMap = [
            ...(oldStateMaps[destination.droppableId] || []),
          ];

          if (sortByVotes) destinationStateMap.push(id);
          else destinationStateMap.splice(destination.index, 0, removed);

          newStateOrderMaps = [
            {
              state: source.droppableId,
              newMap: sourceStateMap,
            },
            {
              state: destination.droppableId,
              newMap: destinationStateMap,
            },
          ];
        }

        updateQuestionLocalAndServer(
          currentRoom.id,
          newStateOrderMaps,
          id,
          destination.droppableId,
          destination.index,
          { resetEditor: false },
        );
      }

      // call next middleware or store.dispatch if this is the last one
      return next(action);
    };
  };
};

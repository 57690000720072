/* global I18n */
/* global Util */

import React, { useEffect, useState } from "react";
import useFocus from "@components/hooks/useFocus";
import QuestionService from "../services/questionService";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import AuthorField from "./authorField";
import TextField from "./textField";

import { useSelector, useDispatch } from "react-redux";
import {
  selectAnonymousQuestions,
  selectMandatoryName,
} from "@store/selectors/activeEvent";

import { createQuestion } from "@store/slices/questionsSlice";

import { selectSelectedRoom } from "@store/slices/questionRoomsSlice";

const useStyles = makeStyles((theme) => ({
  boxSuccess: {
    border: "1px solid #3c763d",
  },
  boxSuccessDisable: {
    border: "1px solid #3c763d",
    backgroundColor: "#EEEEEE",
  },
  boxErrorDisable: {
    border: "1px solid #a94442",
    backgroundColor: "#EEEEEE",
  },
  boxError: {
    border: "1px solid #a94442",
  },
  btn: {
    textTransform: "none",
    fontSize: "1.2rem",
    maxHeight: 31,
    color: "#fff !important",
    backgroundColor: "#594f8d",
    borderColor: "#594f8d",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#594f8d",
      borderColor: "#594f8d",
      boxShadow: "none",
    },
    "&:focus": {
      color: "#fff",
      outline: "none",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1rem",
    },
  },
}));

const FormQuestionVertical = () => {
  const classes = useStyles();
  const service = new QuestionService();
  const config = service.getConfigForm();
  const anonymousQuestions = useSelector(selectAnonymousQuestions);
  const mandatoryName = useSelector(selectMandatoryName);
  const dispatch = useDispatch();

  const maxLength = config.maxLengthQuestion * 1;
  const [disable, setDisable] = useState(false);
  const [inputRef, setInputFocus] = useFocus();
  const [authorName, setAuthorName] = useState(config.defaultName);
  const [textQuestion, setTextQuestion] = useState("");
  const [authorStyle, setAuthorStyle] = useState("");
  const [textStyle, setTextStyle] = useState("");
  const [dynamicLength, setDynamicLength] = useState(maxLength);
  const questionRoom = useSelector(selectSelectedRoom);

  const handlerClick = (e) => {
    e.preventDefault();
    const authorValid = getAuthor(authorName);
    const textValid = getText(textQuestion);
    if (config.event_check && authorValid && textValid) {
      setDisable(true);
      addQuestion();
      setAuthorStyle(classes.boxSuccessDisable);
      setTextStyle(classes.boxSuccessDisable);
      clearStyles();
      generateAlert("success");
    } else {
      generateAlert("error");
      clearStyles();
    }
  };

  const clearStyles = () => {
    setTimeout(() => {
      setDisable(false);
      setAuthorStyle("");
      setTextStyle("");
    }, 2000);
  };

  const checkEnter = (value) => {
    getAuthor(value);
    setInputFocus();
  };

  const generateAlert = (status) => {
    const message =
      status === "success"
        ? config.alertSuccessMessage
        : config.alertErrorMessage;
    Util.alert(message, status, status, 2000);
  };

  const addQuestion = () => {
    const newQuestion = {
      message: textQuestion,
      attendee_id: config.attendeeId,
      chat_room_id: questionRoom.id,
      publishing_name: authorName,
    };

    dispatch(createQuestion({ question: newQuestion }));

    setTextQuestion("");
    setDynamicLength(maxLength);
  };

  const getAuthor = (value, updateStyle = true) => {
    if (anonymousQuestions) {
      setAuthorName("Anonymous");
      if (updateStyle) {
        setAuthorStyle(classes.boxSuccess);
      }
      return true;
    }
    if (mandatoryName && !value) {
      setAuthorName("");
      if (updateStyle) {
        setAuthorStyle(classes.boxError);
      }
      return false;
    }
    setAuthorName(value);
    if (updateStyle) {
      setAuthorStyle(classes.boxSuccess);
    }
    return true;
  };

  const getText = (value) => {
    if (!value.trim()) {
      setTextQuestion("");
      setTextStyle(classes.boxError);
      return false;
    }
    setTextQuestion(value);
    setTextStyle(classes.boxSuccess);
    return true;
  };

  const handleAuthorFocus = () => {
    if (authorStyle) {
      setAuthorStyle("");
    }
  };

  const handleTextFocus = () => {
    if (authorStyle) {
      setTextStyle("");
    }
  };

  useEffect(() => {
    getAuthor(authorName, false);
  }, [anonymousQuestions, mandatoryName]);

  return (
    <div id="ask_your_q" className="card w-100 form-group">
      <div className="card-body">
        <form>
          {!anonymousQuestions && (
            <AuthorField
              name="authorQuestion"
              authorLabel={config.authorLabel}
              authorName={authorName}
              styles={authorStyle}
              disable={disable}
              onFocus={handleAuthorFocus}
              callKeyDown={checkEnter}
              callback={getAuthor}
            />
          )}
          <TextField
            name="textQuestion"
            id="textQuestion"
            textLabel={config.textLabel}
            textPlaceholder={config.textPlaceholder}
            text={textQuestion}
            styles={textStyle}
            lengthValue={dynamicLength}
            onFocus={handleTextFocus}
            ref={inputRef}
            disable={disable}
            callback={getText}
          />
          <Button
            variant="contained"
            className={classes.btn + " submit-question"}
            disabled={disable || !questionRoom}
            onClick={handlerClick}
            disableRipple
          >
            {I18n.t("submit")}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default FormQuestionVertical;

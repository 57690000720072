import { styled } from "@mui/system";

const StyledTag = styled("span")({
  color: "#fff",
  borderRadius: "0.25em",
  padding: "1px 3px",
  fontSize: "9px",
  fontWeight: "700",
  whiteSpace: "nowrap",
  display: "inline",
  margin: "15px",
});

export default StyledTag;

/* global appConfig */

export default class EventService {
  constructor() {}
  getEventIdFromAppConfig() {
    if (
      typeof appConfig === "undefined" ||
      typeof appConfig.event === "undefined"
    )
      return false;
    return appConfig.event.encoded_id;
  }
}

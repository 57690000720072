import React from "react";
import {
  SpinnerWrapper,
  SpinnerContent,
  SpinnerOutsideBorder,
  SpinnerInsideBorder,
} from "./StyledSpinner";

const Spinner = () => {
  return (
    <SpinnerWrapper>
      <SpinnerContent>
        <SpinnerOutsideBorder />
        <SpinnerInsideBorder />
      </SpinnerContent>
    </SpinnerWrapper>
  );
};

export default Spinner;

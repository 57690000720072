import React from "react";
import PropTypes from "prop-types";

import StyledTag from "./StyledTag";

const Tag = ({ text, color, margin }) => {
  return (
    <>
      <StyledTag sx={{ backgroundColor: color, margin: margin }}>
        {text}
      </StyledTag>
    </>
  );
};

Tag.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  margin: PropTypes.string,
};

export default Tag;

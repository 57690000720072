import React from "react";
import PropTypes from "prop-types";

const SurveyDescription = ({ text }) => {
  return (
    <>
      <div className="m-b survey_description">{text}</div>
    </>
  );
};

SurveyDescription.propTypes = {
  text: PropTypes.string,
};

export default SurveyDescription;

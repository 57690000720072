import { configureStore } from "@reduxjs/toolkit";

import { socketMiddleware } from "./middleware/websocketMiddleware";

import { questionsOrderMiddleware } from "./middleware/questionsOrderMiddleware";

import reducer from "./reducer";

export default configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(socketMiddleware)
      .concat(questionsOrderMiddleware),
});

import {
  createEntityAdapter,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import DataService from "@services/dataService";

const dataService = new DataService();

export const getEvent = createAsyncThunk(
  "events/getEvent",
  async ({ eventId }, thunkAPI) => {
    let url = `/api/events/${eventId}`;
    const jwt = thunkAPI.getState().jwt.token;

    const response = await dataService
      .getData(url, jwt)
      .then((res) => res.data);
    return response;
  },
);

export const getEventSettingsOptions = createAsyncThunk(
  "events/getEventSettingsOptions",
  async ({ eventId }, thunkAPI) => {
    let url = `/api/events/${eventId}/settings_options`;
    const jwt = thunkAPI.getState().jwt.token;

    const response = await dataService
      .getData(url, jwt)
      .then((res) => res.data);
    return response;
  },
);

export const updateEventData = createAsyncThunk(
  "events/updateEventData",
  async ({ eventId, changes }) => {
    let url = `/api/events/${eventId}`;

    return dataService.putData(url, { event: changes }).then((res) => res.data);
  },
);

// implementation based on
// https://redux-toolkit.js.org/api/createEntityAdapter
const eventsAdapter = createEntityAdapter();

// selectors from entityAdapter
const eventsSelectors = eventsAdapter.getSelectors((state) => state.events);

const eventsSlice = createSlice({
  name: "events",
  initialState: eventsAdapter.getInitialState({
    loading: false,
    error: "",
    activeEvent: undefined,
    saving: false,
  }),
  reducers: {
    updateEvent: eventsAdapter.updateOne,
    upsertEvent: eventsAdapter.upsertOne,
    loadAndSelectNewEvent(state, action) {
      state.activeEvent = action.payload.id;
      eventsAdapter.upsertOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEvent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEvent.fulfilled, (state, action) => {
      eventsAdapter.upsertOne(state, action.payload);
      state.activeEvent = action.payload.id;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(getEvent.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(updateEventData.pending, (state, action) => {
      eventsAdapter.updateOne(state, {
        id: action.meta.arg.eventId,
        changes: action.meta.arg.changes,
      });
      state.saving = true;
    });
    builder.addCase(updateEventData.rejected, (state, action) => {
      Util.log(`An Error occured: ${action.error.message}`);
      state.saving = false;
      state.error = true;
    });
    builder.addCase(updateEventData.fulfilled, (state, action) => {
      state.saving = false;
      state.error = false;
      eventsAdapter.updateOne(state, action.payload);
    });
    builder.addCase(getEventSettingsOptions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEventSettingsOptions.fulfilled, (state, action) => {
      eventsAdapter.upsertOne(state, action.payload);
      state.loading = false;
      state.error = "";
    });
    builder.addCase(getEventSettingsOptions.rejected, (state, action) => {
      eventsAdapter.updateOne(state, action.payload);
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

//// exports
// active event
export const selectActiveEvent = (state) =>
  state.events && eventsSelectors.selectById(state, state.events.activeEvent);

export const selectLoading = (state) => {
  return state.events.loading;
};

export const selectSaving = (state) => {
  return state.events.saving;
};

export const selectError = (state) => {
  return state.events.error;
};

// actions
export const { updateEvent, loadAndSelectNewEvent, upsertEvent } =
  eventsSlice.actions;

export const {
  selectById: selectEventById,
  selectIds: selectEventIds,
  selectEntities: selectEventEntities,
  selectAll: selectAllEvents,
  selectTotal: selectTotalEvents,
} = eventsAdapter.getSelectors((state) => state.events);

// reducers
export default eventsSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import DataService from "@services/dataService";

const dataService = new DataService();

const loadData = createAsyncThunk("sequence/loadData", async (url) => {
  const response = await dataService.getData(url).then((result) => {
    return result.data;
  });
  return response;
});

const updateData = createAsyncThunk("sequence/updateData", async (urlData) => {
  const bodyData = { sequence: { content: urlData.text } };
  const response = await dataService
    .putData(urlData.url, bodyData)
    .then((result) => result.data);
  return response;
});

export const slideSequenceSlice = createSlice({
  name: "sequence",
  initialState: {
    data: "",
    loadError: "",
    updateError: "",
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [loadData.pending]: (state) => {
      if (!state.loading && !state.data.length) {
        state.loading = true;
      }
    },
    [loadData.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload || "";
    },
    [loadData.rejected]: (state, action) => {
      state.loading = false;
      state.loadError = action.payload;
    },
    [updateData.pending]: (state) => {
      if (!state.loading && state.data.length) {
        state.loading = true;
      }
    },
    [updateData.fulfilled]: (state) => {
      state.loading = false;
      state.data = "";
    },
    [updateData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  },
});

export const selectData = (state) => {
  return state.sequence;
};

export { loadData, updateData };
export default slideSequenceSlice.reducer;

import { styled } from "@mui/system";

export const ProgressBarContainer = styled("div")({
  display: "flex",
  position: "relative",
  width: "100%",
  height: "65px",
  margin: "10px 0px",
  backgroundColor: "#DADADA",
  borderRadius: "4px",
});

export const InProgress = styled("div")(({ color, width }) => ({
  display: "flex",
  position: "absolute",
  width: width,
  backgroundColor: color,
  justifyContent: "space-between",
  alignItems: "center",
  height: "100%",
  borderRadius: "4px",
  transition: "1s linear",
  zIndex: "1",
}));

export const CountdownMessageWrapper = styled("div")(({ theme }) => ({
  overflowX: "hidden",
  whiteSpace: "nowrap",
  position: "absolute",
  height: "100%",
  paddingRight: "0 100%",
  display: "flex",
  alignItems: "center",
  zIndex: "2",
  right: "0%",
  maxWidth: "500px",
  [theme.breakpoints.down(670)]: {
    maxWidth: "250px",
  },
  [theme.breakpoints.down(500)]: {
    maxWidth: "170px",
  },
  [theme.breakpoints.down(400)]: {
    maxWidth: "100px",
  },
  [theme.breakpoints.down(350)]: {
    maxWidth: "80px",
  },
  [theme.breakpoints.up(671)]: {
    maxWidth: "500px",
  },
}));

export const CountdownData = styled("span")({
  fontFamily: "Open sans",
  fontSize: "30px",
  fontWeight: 900,
  color: "#FFFFFF",
  textShadow: "0px 4px 10px #ACACAC",
  margin: "0px 20px",
});

export const CountdownMessageText = styled("span")(({ theme }) => ({
  [theme.breakpoints.down(671)]: {
    "@keyframes scrollText": {
      "0%": {
        transform: "translate(100%, 0)",
      },
      "100%": {
        transform: "translate(-100%, 0)",
      },
    },
    animation: "scrollText 10s infinite linear",
  },
}));

import React, { useState, useEffect } from "react";
import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";
import { selectActiveEvent } from "@store/slices/eventsSlice";

import { selectActiveEventState } from "@store/selectors/activeEvent";

import { useSelector } from "react-redux";
import useInterval from "@components/hooks/useInterval";

const useStyles = makeStyles({
  root: {
    height: "250px",
    position: "relative",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  pre: {
    backgroundColor: "#5dcff3",
    color: "#ffffff",
  },
  post: {
    backgroundColor: "#92cf5c",
    color: "#fbfdfa",
  },
  unavailable: {
    backgroundColor: "#fcc44d",
    color: "#ffffff",
  },
  message: {
    textAlign: "center",
  },
});

const EventSlate = () => {
  const classes = useStyles();
  const event = useSelector(selectActiveEvent);
  const [startsInSeconds, setStartsInSeconds] = useState("");
  const momentOfEventStart = moment(event.startsAtTimestamp * 1000);
  const state = useSelector(selectActiveEventState);

  moment.locale(App.getMomentLang());

  let stateClass;
  let message;

  const calculateNewTime = () => {
    const currentMoment = moment();
    setStartsInSeconds(
      moment.duration(momentOfEventStart.diff(currentMoment)).asSeconds(),
    );
  };

  useEffect(() => calculateNewTime(), []);

  useInterval(() => {
    calculateNewTime();
  }, 10000);

  switch (state) {
    case "archived":
      stateClass = classes.unavailable;
      message = I18n.t("frontend.video_archived");
      break;
    case "pre":
      stateClass = classes.pre;
      if (startsInSeconds > 60) {
        message = I18n.t("frontend.event_starts", {
          at: momentOfEventStart.fromNow(),
        });
      } else {
        message = I18n.t("frontend.event_starting_soon");
      }
      break;
    case "post":
      stateClass = classes.post;
      message = I18n.t("frontend.thank_you_for_watching");
      break;
    default:
      stateClass = classes.unavailable;
      message = I18n.t("frontend.video_unavailable");
  }

  return (
    <>
      <div id="newSlate" className={`${classes.root} ${stateClass}`}>
        <h3
          className={classes.message}
          // this prevents html escaping
          dangerouslySetInnerHTML={{ __html: message }}
        />
      </div>
    </>
  );
};

export default EventSlate;

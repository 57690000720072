import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import DataService from "@services/dataService";

const dataService = new DataService();
const eventId =
  (typeof appConfig != "undefined" &&
    appConfig.event &&
    appConfig.event.encoded_id) ||
  "";
const spotlightedUserId =
  (typeof appConfig != "undefined" &&
    appConfig.event &&
    appConfig.event.spotlighted_user) ||
  "";

const getConfiguration = createAsyncThunk(
  "conference/getConfiguration",
  async (eventToken = false) => {
    let url = `/admin/events/${eventId}/conference/join`;
    if (eventToken) {
      url = url.concat(`?token=${eventToken}`);
    }

    const response = await dataService.getData(url);
    return {
      meetingInfo: response.data.meeting,
      attendeeInfo: response.data.attendee,
    };
  },
);

export const conferenceSlice = createSlice({
  name: "conference",
  initialState: {
    conference: {},
    eventId: eventId,
    moderatedDevices: {
      video: false,
      mic: true,
      blur: false,
    },
    spotlightedSpeakerId: spotlightedUserId,
    loading: false,
    error: "",
    currentLayout: "device-selection",
  },
  reducers: {
    setCurrentLayout: (state, action) => {
      state.currentLayout = action.payload;
    },
    leaveMeeting: (state) => {
      state.conference = {};
    },
    moderateDevices: (state, action) => {
      const current_att =
        state.conference.attendeeInfo?.AttendeeId ===
        action.payload.attendee_id;

      switch (action.payload.message[0]) {
        case "kick":
          if (current_att) state.moderatedDevices["kick"] = true;
          break;
        case "pin":
          if (state.spotlightedSpeakerId !== action.payload.attendee_id) {
            state.spotlightedSpeakerId = action.payload.attendee_id;
          } else {
            state.spotlightedSpeakerId = "";
          }
          if (current_att) {
            action.payload.message.slice(1).forEach((item) => {
              state.moderatedDevices[item] = true;
            });
          }
          break;
        default:
          if (current_att) {
            state.moderatedDevices[action.payload.message] =
              !state.moderatedDevices[action.payload.message];
          }
      }
    },
  },
  extraReducers: {
    [getConfiguration.pending]: (state) => {
      if (!state.loading) {
        state.loading = true;
      }
    },
    [getConfiguration.fulfilled]: (state, action) => {
      state.loading = false;
      state.conference = action.payload;
    },
    [getConfiguration.rejected]: (state, action) => {
      state.loading = false;
      state.conference = {};
      state.error = action.error.message;
    },
  },
});

export const selectCurrentLayout = (state) => state.conference.currentLayout;

export const selectLoading = (state) => state.conference.loading;

export const selectCurrentAttendeeId = (state) =>
  state.conference.conference.attendeeInfo &&
  state.conference.conference.attendeeInfo.AttendeeId;

export const selectConfiguration = (state) => {
  return state.conference;
};

export const selectModeratedDevice = (state) => {
  return state.conference.moderatedDevices;
};

export const selectSpotlightedSpeakerId = (state) => {
  return state.conference.spotlightedSpeakerId;
};

export { getConfiguration };
export const { leaveMeeting, moderateDevices, setCurrentLayout } =
  conferenceSlice.actions;
export default conferenceSlice.reducer;

/* global I18n */

import React, { useCallback, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import { Formik, Form } from "formik";
import SurveyQuestionInput from "./SurveyQuestionInput";
import DataService from "@services/dataService";
import SurveySubmitButton from "./SurveySubmitButton";
import WidgetCard from "@shared/materialUI/widgetCard";
import SurveyDescription from "./SurveyDescription";
import SurveyAnonymouseNote from "./SurveyAnonymousNote";
import ThankYouMessage from "./ThankYouMessage";
import SurveyTitle from "./SurveyTitle";
import { object as yupObject, string as yupString } from "yup";

import {
  selectQuestionsForActiveSurvey,
  getSurveyQuestions,
} from "@store/slices/survey/surveysQuestionsSlice";

import { getSurvey, selectActiveSurvey } from "@store/slices/surveysSlice";

import { selectIsFocusViewTemplate } from "@store/slices/metaSlice";

const useStyles = makeStyles(() => ({
  root: {
    fontSize: "13px",
    fontFamily: '"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
    color: "#717171",
  },
}));

const SurveyForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dataService = new DataService();
  const survey = useSelector(selectActiveSurvey);
  const isFocusViewTemplate = useSelector(selectIsFocusViewTemplate);
  const surveyQuestions = useSelector(selectQuestionsForActiveSurvey);
  const jwt = useSelector((state) => state.jwt.token);

  useEffect(() => {
    dispatch(getSurveyQuestions({ surveyId: survey.id }));
  }, [survey.id]);

  // Allow to fill the form via JS, used for load test scripts
  // https://github.com/jaredpalmer/formik/issues/2212
  window.surveyFormRef = React.createRef();

  const sendNewAttempt = useCallback(
    (values, { setSubmitting }) => {
      const attemptParams = {
        answers: { ...values },
        language: I18n.locale,
      };

      const url = `/api/surveys/${survey.id}/attempt`;
      dataService
        .postData(url, attemptParams, jwt)
        .then(() => {
          dispatch(getSurvey({ surveyId: survey.id }));
          setSubmitting(false);
        })
        .catch((err) => {
          alert(`${I18n.t("surveys.new_attempt.error")}: ` + err);
          setSubmitting(false);
        });
    },
    [survey],
  );

  const inits = {};
  surveyQuestions.forEach((question) => {
    inits[question.id] = "";
  });

  // Validate that there are not unanswered mandatory questions
  const validationObject = {};
  surveyQuestions.forEach((question) => {
    if (question.mandatory) {
      validationObject[question.id] = yupString()
        .trim()
        .required(I18n.t("surveys.mandatory_question_error"));
    }
  });
  const mandatorySchema = yupObject().shape(validationObject);

  const mandatoryHint = (
    <p id="mandatory-hint">
      <span className="text-danger">*</span>
      &nbsp;
      <em>{I18n.t("surveys.required_fields")}</em>
    </p>
  );

  const isAnyQuestionMandatory = surveyQuestions.some(
    (question) => question.mandatory,
  );

  const form = (
    <>
      <SurveyAnonymouseNote
        note={
          survey.anonymous
            ? I18n.t("surveys.is_anonymous")
            : I18n.t("surveys.not_anonymous")
        }
      />
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        innerRef={window.surveyFormRef}
        initialValues={inits}
        validationSchema={mandatorySchema}
        onSubmit={sendNewAttempt}
      >
        {({ errors }) => (
          <Form id="new_survey_attempt">
            <section className="comment-list block widget">
              {surveyQuestions.map((question, index) => {
                return (
                  <SurveyQuestionInput
                    question={question}
                    questionNumber={index + 1}
                    key={question.id}
                    error={errors[question.id]}
                  />
                );
              })}

              <SurveySubmitButton />
            </section>
            {isAnyQuestionMandatory && mandatoryHint}
          </Form>
        )}
      </Formik>
    </>
  );

  const wrapper = (
    <div id="survey" className={classes.root}>
      {!isFocusViewTemplate && <SurveyTitle text={survey.name} />}
      {survey.description && survey.canParticipate && (
        <>
          <SurveyDescription text={survey.description} />
          <div className={"line line-lg line-dashed pull-in"}></div>
        </>
      )}
      {survey.canParticipate ? (
        form
      ) : (
        <ThankYouMessage withDottedLine={!isFocusViewTemplate} />
      )}
    </div>
  );

  const widgetStyle = <WidgetCard head={survey.name} body={wrapper} />;
  const plainStyle = wrapper;

  return isFocusViewTemplate ? widgetStyle : plainStyle;
};

export default SurveyForm;

import { styled } from "@mui/system";

export const StyledCardColumns = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
});

export const FirstColumn = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  [theme.breakpoints.up("sm")]: {
    width: "50%",
  },
  [theme.breakpoints.up("lg")]: {
    width: "70%",
  },
}));

export const SecondColumn = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  [theme.breakpoints.up("sm")]: {
    width: "50%",
  },
  [theme.breakpoints.up("lg")]: {
    width: "30%",
  },
}));

export const RowStyle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column-reverse",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    alignItems: "center",
  },
}));

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "@services/dataService";

const dataService = new DataService();

export const getCountdownData = createAsyncThunk(
  "countdown/getCountdownData",
  async (eventId) => {
    let url = `/api/events/${eventId}/countdown.json`;
    const response = await dataService.getData(url);
    return response.data;
  },
);

export const updateCountdownData = createAsyncThunk(
  "countdown/updateCountdownData",
  async ({ eventId, changes }) => {
    let url = `/api/events/${eventId}/countdown.json`;
    const response = await dataService.putData(url, changes);
    return response.data;
  },
);

const calculateTimeLeft = (timeNow, startsAt, duration) => {
  const now = new Date(timeNow);
  const end = Date.parse(startsAt) + duration * 1000;
  let timeLeft = end - now;
  if (timeLeft < 0) timeLeft = 0;
  const timeLeftInSeconds = timeLeft / 1000;
  const timeLeftPercentage = Math.round((timeLeftInSeconds * 100) / duration);
  return { timeLeft: timeLeft, timeLeftPercentage: timeLeftPercentage };
};

export const countdownSlice = createSlice({
  name: "countdown",
  initialState: {
    name: "countdown",
    countdownData: {},
    loading: false,
    timeLeft: 0,
    timeLeftPercentage: 0,
    error: "",
    saving: false,
  },
  reducers: {
    updateCountdownTimeLeft: (state, action) => {
      if (!state.countdownData || !state.countdownData.active) {
        state.timeLeft = state.countdownData.duration * 1000;
      } else {
        const { timeLeft, timeLeftPercentage } = calculateTimeLeft(
          action.payload,
          state.countdownData.startsAt,
          state.countdownData.duration,
        );
        state.timeLeft = timeLeft;
        state.timeLeftPercentage = timeLeftPercentage;
      }
    },
    updateCountdownOnClient: (state, action) => {
      state.countdownData = action.payload;
    },
  },
  extraReducers: {
    [getCountdownData.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getCountdownData.fulfilled]: (state, action) => {
      state.loading = false;
      state.countdownData = action.payload;
      const { timeLeft, timeLeftPercentage } = calculateTimeLeft(
        new Date().valueOf(),
        state.countdownData.startsAt,
        state.countdownData.duration,
      );

      state.timeLeft = timeLeft;
      state.timeLeftPercentage = timeLeftPercentage;
    },
    [getCountdownData.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
      state.saving = false;
    },
    [updateCountdownData.pending]: (state, action) => {
      state.countdownData = {
        ...state.countdownData,
        ...action.meta.arg.changes,
      };
      state.saving = true;
    },
    [updateCountdownData.fulfilled]: (state, action) => {
      state.countdownData = action.payload;
      state.saving = false;
    },
    [updateCountdownData.rejected]: (state, action) => {
      state.error = action.error.message;
      state.saving = false;
    },
  },
});

export const {
  updateCountdownTimeLeft,
  updateCountdownOnClient,
  updateCountdownProgressBar,
} = countdownSlice.actions;

export const selectCountdownData = (state) =>
  state.countdown && state.countdown.countdownData;

export const selectCountdownMessage = (state) =>
  selectCountdownData(state) && selectCountdownData(state).message;

export const selectCountdownDuration = (state) =>
  (selectCountdownData(state) && selectCountdownData(state).duration) || 0;

export const selectCountdownSaving = (state) =>
  state.countdown && state.countdown.saving;

export const selectCountdownError = (state) =>
  state.countdown && state.countdown.error;

export const selectCountdownActive = (state) =>
  state.countdown && state.countdown.countdownData.active;

export const selectCountdownTimeLeft = (state) =>
  (state.countdown && state.countdown.timeLeft) ||
  selectCountdownDuration(state);

export const selectCountdownTimeLeftPercentage = (state) => {
  return state.countdown.timeLeftPercentage;
};

export default countdownSlice.reducer;

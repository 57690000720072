import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import videojs from "video.js";

import styles from "./vjsBaseSkin.lazycss";

const SUPPORTED_LANGUAGES = [
  "en",
  "de",
  "fr",
  "it",
  "ar",
  "pt-BR",
  "zh-CN",
  "ca",
  "cs",
  "el",
  "es",
  "he",
  "hu",
  "ja",
  "nl",
  "pl",
  "pt",
  "ro",
  "ru",
  "sr",
  "sv",
  "tr",
  "vi",
  "ua",
  "de-sl",
  "en-st",
  "de-st",
];

// Implementation inspired by https://docs.videojs.com/tutorial-react.html
export const VideoJS = ({ options, onReady }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  const loadLanguage = async (lang) => {
    try {
      return await import(`video.js/dist/lang/${lang}.json`);
    } catch (error) {
      switch (lang) {
        case "de-st":
          return await import(`video.js/dist/lang/de.json`);
        case "de-sl":
          return await import(`video.js/dist/lang/de.json`);
        case "en-st":
          return await import(`video.js/dist/lang/en.json`);
        default:
          Util.log(`Failed to load language ${lang}, fallback to english`);
          // fallback to english for unsupported languages
          return await import(`video.js/dist/lang/en.json`);
      }
    }
  };

  const loadLanguages = async () => {
    for (const lang of SUPPORTED_LANGUAGES) {
      loadLanguage(lang).then((data) => {
        videojs.addLanguage(lang, data);
        Util.log(`Loaded language ${lang}`);
      });
    }
  };

  useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      loadLanguages();
      const player = (playerRef.current = videojs(videoElement, options, () => {
        Util.log("player is ready");
        onReady && onReady(player);
      }));
      // player.addClass('vjs-mes-skin');
    } else {
      // you can update player here [update player through props]
      // const player = playerRef.current;
      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [options]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    // Load and activate default video-js styling only when new player is used.
    // When loading it globally it will break old player stylings
    styles.use();
    return () => {
      styles.unuse();
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  return (
    <div data-vjs-player>
      <video ref={videoRef} className={"video-js vjs-big-play-centered"} />
    </div>
  );
};

VideoJS.propTypes = {
  options: PropTypes.object,
  onReady: PropTypes.func,
};

export default VideoJS;

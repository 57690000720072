import React from "react";
import BorderLinearProgress from "../BorderLinearProgress/BorderLinearProgress";
import PropTypes from "prop-types";

const SurveyChartData = ({ data, setActiveIndex, activeIndex }) => {
  return (
    <>
      {data.map((option, index) => {
        return (
          <div
            key={option.id}
            style={{
              filter:
                activeIndex === index ? "brightness(95%)" : "brightness(100%)",
            }}
            onMouseEnter={() => {
              setActiveIndex(index);
            }}
            onMouseLeave={() => {
              setActiveIndex(-1);
            }}
          >
            <h6 style={{ fontWeight: activeIndex === index ? "900" : "500" }}>
              {option.text} {Math.round(option.percent)}% ({option.value})
            </h6>
            <BorderLinearProgress
              value={option.percent}
              barcolor={option.color}
              variant="determinate"
            />
          </div>
        );
      })}
    </>
  );
};

SurveyChartData.propTypes = {
  data: PropTypes.array,
  setActiveIndex: PropTypes.func.isRequired,
  activeIndex: PropTypes.number,
};

export default SurveyChartData;

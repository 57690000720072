import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import DataService from "@services/dataService";
const dataService = new DataService();

export const getUserData = createAsyncThunk(
  "users/getUserData",
  async (userId) => {
    let url = `/api/users/${userId}.json`;
    const response = await dataService.getData(url);
    return response.data;
  },
);

export const updateUserData = createAsyncThunk(
  "users/updateUserData",
  async ({ userId, changes }) => {
    let url = `/api/users/${userId}`;
    const response = await dataService.putData(url, changes);
    return response.data;
  },
);

const usersAdapter = createEntityAdapter();
const usersSelector = usersAdapter.getSelectors((state) => state.users);

const usersSlice = createSlice({
  name: "users",
  initialState: usersAdapter.getInitialState({
    currentUserId: undefined,
    loading: false,
    error: "",
    saving: false,
  }),
  reducers: {
    setCurrentUserId(state, action) {
      Util.log(action.payload);
      state.currentUserId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserData.fulfilled, (state, action) => {
      usersAdapter.upsertOne(state, action.payload);
      state.loading = false;
      state.error = "";
    });
    builder.addCase(getUserData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(updateUserData.pending, (state, action) => {
      usersAdapter.updateOne(state, {
        id: action.meta.arg.userId,
        changes: action.meta.arg.changes,
      });
      state.saving = true;
    });
    builder.addCase(updateUserData.rejected, (state, action) => {
      Util.log(`An Error occured: ${action.error.message}`);
      state.saving = false;
      state.error = true;
    });
    builder.addCase(updateUserData.fulfilled, (state, action) => {
      state.saving = false;
      state.error = false;
      usersAdapter.updateOne(state, action.payload);
    });
  },
});

export const selectCurrentUserId = (state) => {
  return state.users.currentUserId;
};

export const selectUser = (state) =>
  state.users && usersSelector.selectById(state, state.users.currentUserId);

export const selectSaving = (state) => {
  return state.users.saving;
};

export const selectError = (state) => {
  return state.users.error;
};

export const selectLoading = (state) => {
  return state.users.loading;
};

export const { setCurrentUserId } = usersSlice.actions;

export default usersSlice.reducer;

import questionsListReducer from "./slices/questionsListSlice";
import countAttendeesReducer from "./slices/countAttendeeSlice";
import overviewReducer from "./overviewSlice";
import conferenceReducer from "./slices/conferenceSlice";
import eventAnalyticsReducer from "./slices/eventAnalyticsSlice";
import slideSequenceReducer from "./slices/slideSequenceSlice";
import phoneConferencesReducer from "./slices/phoneConferencesSlice";
import jwtSliceReducer from "./slices/jwtSlice";
import questionRoomsReducer from "./slices/questionRoomsSlice";
import metaSlice from "./slices/metaSlice";
import statisticsSlice from "./slices/statisticsSlice";
import iframesSlice from "./slices/iframesSlice";
import countdownSlice from "./slices/countdownSlice";
import surveysQuestionsReducer from "./slices/survey/surveysQuestionsSlice";
import surveysAnswersReducer from "./slices/survey/surveysAnswersSlice";
import eventsReducer from "./slices/eventsSlice";
import streamsReducer from "./slices/streamsSlice";
import surveysReducer from "./slices/surveysSlice";
import questionsReducer from "./slices/questionsSlice";
import uploaderSlice from "./slices/uploaderSlice";
import usersSlice from "./slices/usersSlice";

import { combineReducers } from "redux";

const reducer = combineReducers({
  questionsList: questionsListReducer,
  countAttendees: countAttendeesReducer,
  overview: overviewReducer,
  conference: conferenceReducer,
  eventAnalytics: eventAnalyticsReducer,
  sequence: slideSequenceReducer,
  jwt: jwtSliceReducer,
  meta: metaSlice,
  uploader: uploaderSlice,
  // normalized, database baked slices
  surveyQuestions: surveysQuestionsReducer,
  surveyAnswers: surveysAnswersReducer,
  phoneConferences: phoneConferencesReducer,
  events: eventsReducer,
  streams: streamsReducer,
  questions: questionsReducer,
  questionRooms: questionRoomsReducer,
  surveys: surveysReducer,
  statistics: statisticsSlice,
  iframes: iframesSlice,
  countdown: countdownSlice,
  users: usersSlice,
});

export default reducer;
